import { Drawer, DrawerContent } from "@/components/shadcn/drawer.tsx";
import {
  AdDataProps,
  GetAdTemplate,
  ManuallySaveTemplateToBoard,
} from "@/components/templates/AdCard";
import { Link, X, User, Heart, Download } from "@mynaui/icons-react";
import { TemplatesFooter } from "./custom-components/TemplatesHeader";
import { Button } from "@/components/shadcn/button";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import showToastNotification from "@/hooks/useShowToast";
import { Dispatch, SetStateAction, useState } from "react";
import {
  GetLandingAdTemplate,
  LandingAdDataProps,
  ManuallySaveLandingAdToBoard,
} from "./templates/LandingAdCard";
import { ScrollArea } from "./shadcn/scroll-area";
import { trpc } from "@/utils/trpc";
import { Stack, Text } from "@/components/custom-components";
import { Badge } from "./shadcn/badge";
import { Mobile } from "@mynaui/icons-react";
import { Tooltip, TooltipTrigger, TooltipContent } from "./shadcn/tooltip";

const TemplateMediaSection = ({ data }: { data: AdDataProps }) => (
  <div className=" flex flex-col my-12 mx-8 items-center">
    <img
      alt={"ad display"}
      data-original-width={data.Ad["Example Image"][0].width}
      data-original-height={data.Ad["Example Image"][0].height}
      // style={{
      //   width: adData.DesiredWidth,
      //   height: height,
      // }}
      loading={"lazy"}
      src={data.Ad.imageUrl ?? data.Ad["Example Image"][0].url}
      className={`w-full rounded-md min-h-30 max-w-[41.25rem]`}
    />
  </div>
);
const TemplateBodySection = ({
  data,
  onCloseDrawer,
  saveOpen,
  setSaveOpen,
  copyToClipboard,
  numberOfUsers,
  numberOfSaves,
  dateSaved,
}: {
  data: AdDataProps;
  onCloseDrawer: () => void;
  saveOpen: boolean;
  setSaveOpen: Dispatch<SetStateAction<boolean>>;
  copyToClipboard: (x: string) => void;
  numberOfUsers: number;
  numberOfSaves: number;
  dateSaved: string;
}) => (
  <div className="flex flex-col rounded lg:h-full w-full lg:w-[28rem]">
    <div className={"flex justify-end"}>
      <span onClick={onCloseDrawer} className={"p-1 cursor-pointer"}>
        <X />
      </span>
    </div>

    <div className="flex flex-col  px-2 lg:px-8 py-3 pt-0 lg:py-12 lg:pt-4 gap-3 lg:gap-6">
      <TemplatesFooter
        footerTitle={"Template"}
        footerType={"by CreativeOS"}
        titleColor="text-themeforeground"
        creativeOsTextColor="thememutedforeground"
        rhsComponents={
          <div className="flex gap-2">
            {!data.IsPublic && (
              <ManuallySaveTemplateToBoard
                open={saveOpen}
                onOpenChange={setSaveOpen}
                data={data}
                variant="outline"
              />
            )}
            <div className="flex gap-2">
              <Tooltip>
                <TooltipTrigger asChild>
                  <a
                    href={data.Ad.imageUrl ?? data.Ad["Example Image"][0].url}
                    download={"image.png"}
                  >
                    <Button variant={"outline"} size={"icon"}>
                      <Download className="w-5 h-5" />
                    </Button>
                  </a>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Download as image</p>
                </TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    onClick={() => {
                      copyToClipboard(
                        `${window.location.origin}/share/templates/${data.Ad.atID}`,
                      );
                      showToastNotification("success", {
                        message: "Copied!",
                      });
                    }}
                    variant="outline"
                    size={"icon"}
                  >
                    <Link className="w-5 h-5 " />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Share a public link</p>
                </TooltipContent>
              </Tooltip>
            </div>
          </div>
        }
      />

      <div className="flex flex-col gap-1.5">
        <span className="font-semibold text-xl leading-7 text-themeforeground">
          Template
        </span>
        <div className="flex flex-row gap-4">
          <span className="font-normal text-sm leading-5 text-thememutedforeground text-nowrap">
            {dateSaved}
          </span>
          <span className="font-normal text-sm leading-5 text-thememutedforeground flex flex-row gap-0.5 items-center">
            <User className="w-4 h-4" />
            <span className="text-nowrap">{numberOfUsers ?? 0} users</span>
          </span>
          <span className="font-normal text-sm leading-5 text-thememutedforeground flex flex-row gap-0.5 items-center text-nowrap">
            <Heart className="w-4 h-4" />
            {numberOfSaves} saves
          </span>
        </div>
      </div>
      <GetAdTemplate buttonVersion Ad={data.Ad} IsPublic={data.IsPublic} />
      {data.Ad.Tags && (
        <div className="flex justify-between items-center">
          <Text weight={"medium"} size={"sm"}>
            Tags
          </Text>
          <Badge className="font-medium text-nowrap w-fit" variant={"outline"}>
            {data.Ad.Tags}
          </Badge>
        </div>
      )}
    </div>
  </div>
);
const LanderEmailMediaSection = ({
  landingData,
  type,
}: {
  landingData: LandingAdDataProps;
  type?: "email" | "landing-page";
}) => (
  <div className={"my-12 mx-8 flex flex-col items-center"}>
    <div
      className={`rounded  flex flex-col ${type === "email" ? "max-w-[30.5rem]" : ""}`}
    >
      <div className="bg-themebackground rounded-t-lg w-full h-5 gap-5 flex justify-between py-1.5 px-2">
        <div className="flex gap-1.5">
          <div className="rounded-full bg-slate-300 h-2 w-2" />
          <div className="rounded-full bg-slate-300 h-2 w-2" />
          <div className="rounded-full bg-slate-300 h-2 w-2" />
        </div>
        <div className="rounded-sm bg-slate-300 h-2 w-full" />

        <div className="flex gap-1.5">
          <div className="rounded-full  h-2 w-2" />
          <div className="rounded-full h-2 w-2" />
          <div className="rounded-full h-2 w-2" />
        </div>
      </div>

      <img
        alt={
          type === "email"
            ? "screenshot of a email template"
            : "screenshot of a landing page template"
        }
        loading={"lazy"}
        className={`w-full rounded-b-md`}
        src={landingData.imageUrl ?? landingData.Screenshot}
      />
    </div>
  </div>
);

const LanderEmailBodySection = ({
  onCloseDrawer,
  copyToClipboard,
  numberOfUsers,
  landingData,
  dateSaved,
  type,
}: {
  onCloseDrawer: () => void;
  copyToClipboard: (x: string) => void;
  numberOfUsers: number;
  dateSaved: string;
  landingData: LandingAdDataProps;
  type?: "email" | "landing-page";
}) => {
  const [saveOpen, setSaveOpen] = useState<boolean>(false);

  return (
    <div className="flex flex-col rounded lg:h-full w-full lg:w-[28rem]">
      <div className={"flex justify-end"}>
        <span onClick={onCloseDrawer} className={"p-1 cursor-pointer"}>
          <X />
        </span>
      </div>

      <div className="flex flex-col  px-2 lg:px-8 py-3 pt-0 lg:py-12 lg:pt-4 gap-3 lg:gap-6">
        <TemplatesFooter
          footerTitle={"Template"}
          footerType={"by CreativeOS"}
          titleColor="themeforeground"
          creativeOsTextColor="thememutedforeground"
          rhsComponents={
            <div className="flex gap-2">
              {landingData && (
                <ManuallySaveLandingAdToBoard
                  open={saveOpen}
                  onOpenChange={setSaveOpen}
                  data={landingData}
                  variant={"outline"}
                  type={type ?? "email"}
                />
              )}
              <div className={"flex gap-2"}>
                <Tooltip>
                  <TooltipTrigger>
                    <a
                      href={landingData.imageUrl ?? landingData.Screenshot}
                      download={"image.png"}
                    >
                      <Button variant={"outline"} size={"icon"}>
                        <Download className="w-5 h-5" />
                      </Button>
                    </a>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Download as image</p>
                  </TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger>
                    <Button
                      onClick={() => {
                        //We need a page to route the user to the landing and email link
                        copyToClipboard(
                          `${window.location.origin}/share/templates/${type === "email" ? "emails" : type === "landing-page" ? "landing-pages" : ""}/${landingData.ID}`,
                        );
                        showToastNotification("success", {
                          message: "Copied!",
                        });
                      }}
                      size={"icon"}
                      variant="outline"
                    >
                      <Link className="w-5 h-5 " />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Share a public link</p>
                  </TooltipContent>
                </Tooltip>
              </div>
            </div>
          }
        />

        <div className="flex flex-col gap-1.5">
          <span className="font-semibold text-xl leading-7 text-themeforeground">
            Template
          </span>
          <div className="flex flex-row gap-4 flex-wrap">
            <span className="font-normal text-sm leading-5 text-thememutedforeground text-nowrap">
              {dateSaved}
            </span>
            <span className="font-normal text-sm leading-5 text-thememutedforeground flex flex-row gap-0.5 items-center">
              <User className="w-4 h-4" />
              <span className="text-nowrap">
                {numberOfUsers ?? 0}{" "}
                {(numberOfUsers ?? 0) > 1 ? "user" : "users"}
              </span>
            </span>
          </div>
        </div>
        <GetLandingAdTemplate buttonVersion type={type!} Ad={landingData} />
        {type === "landing-page" && (
          <Stack className="gap-1">
            <div className="flex gap-1.5 items-center">
              <Mobile />
              <Text weight={"medium"} size={"sm"}>
                Mobile Design Included!
              </Text>
            </div>
            <Text
              weight={"medium"}
              size={"xs"}
              className="text-thememutedforeground"
            >
              Both Desktop and Mobile designs are included in the Figma file.
            </Text>
          </Stack>
        )}
        {landingData.category && (
          <div className="flex justify-between items-center">
            <Text weight={"medium"} size={"sm"}>
              Tags
            </Text>
            <Badge
              className="font-medium text-nowrap w-fit"
              variant={"outline"}
            >
              {landingData.category}
            </Badge>
          </div>
        )}
      </div>
    </div>
  );
};

export default function TemplateDetails({
  open,
  onCloseDrawer,
  data,
  landingData,
  type,
}: {
  open: boolean;
  onCloseDrawer: () => void;
  data?: AdDataProps;
  type?: "email" | "landing-page";
  landingData?: LandingAdDataProps;
}) {
  const [saveOpen, setSaveOpen] = useState<boolean>(false);
  const [, copyToClipboard] = useCopyToClipboard();
  const { data: numberOfUsers } = trpc.getAdTemplateDownloads.useQuery({
    adId: (data?.Ad["Creative ID"] ?? landingData?.ID ?? "").toString(),
  });
  const { data: numberOfSaves } = trpc.getAdTemplateSaves.useQuery({
    adId: `${data?.Ad["Creative ID"] ?? landingData?.ID ?? ""}`,
  });

  const formatDate = (timestamp: string) => {
    const date = new Date(timestamp);
    return `Posted ${date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}`;
  };

  return (
    <Drawer open={open} onOpenChange={onCloseDrawer}>
      {data && (
        <DrawerContent
          onInteractOutside={saveOpen ? (e) => e.preventDefault() : undefined}
          className={"h-[90vh] p-1 flex flex-col-reverse lg:flex-row w-full"}
        >
          <ScrollArea
            className={"bg-themeaccent rounded h-full w-full hidden lg:flex"}
          >
            <TemplateMediaSection data={data} />
          </ScrollArea>
          <div className="hidden lg:flex">
            <TemplateBodySection
              dateSaved={formatDate(data.Ad.Created)}
              data={data}
              onCloseDrawer={onCloseDrawer}
              saveOpen={saveOpen}
              setSaveOpen={setSaveOpen}
              copyToClipboard={copyToClipboard}
              numberOfUsers={numberOfUsers ?? 0}
              numberOfSaves={numberOfSaves ?? 0}
            />
          </div>
          <ScrollArea className="flex lg:hidden h-full">
            <TemplateBodySection
              dateSaved={formatDate(data.Ad.Created)}
              data={data}
              onCloseDrawer={onCloseDrawer}
              saveOpen={saveOpen}
              setSaveOpen={setSaveOpen}
              copyToClipboard={copyToClipboard}
              numberOfUsers={numberOfUsers ?? 0}
              numberOfSaves={numberOfSaves ?? 0}
            />
            <ScrollArea
              className={"bg-themeaccent rounded h-full w-full min-h-[65vh]"}
            >
              <TemplateMediaSection data={data} />
            </ScrollArea>
          </ScrollArea>
        </DrawerContent>
      )}
      {landingData && (
        <DrawerContent
          className={"h-[90vh] p-1 flex flex-col lg:flex-row w-full"}
        >
          <ScrollArea
            className={"bg-themeaccent rounded h-full w-full hidden lg:flex"}
          >
            <LanderEmailMediaSection type={type} landingData={landingData} />
          </ScrollArea>
          <div className="hidden lg:flex">
            <LanderEmailBodySection
              type={type}
              landingData={landingData}
              dateSaved={formatDate(landingData.created)}
              onCloseDrawer={onCloseDrawer}
              copyToClipboard={copyToClipboard}
              numberOfUsers={numberOfUsers ?? 0}
            />
          </div>
          <ScrollArea className="flex lg:hidden h-full">
            <LanderEmailBodySection
              type={type}
              landingData={landingData}
              dateSaved={formatDate(landingData.created)}
              onCloseDrawer={onCloseDrawer}
              copyToClipboard={copyToClipboard}
              numberOfUsers={numberOfUsers ?? 0}
            />
            <ScrollArea
              className={"bg-themeaccent rounded h-full w-full min-h-[65vh]"}
            >
              <LanderEmailMediaSection type={type} landingData={landingData} />
            </ScrollArea>
          </ScrollArea>
        </DrawerContent>
      )}
    </Drawer>
  );
}

import { trpc } from '@/App';
import { Stack, Text } from '@/components/custom-components';
import { Loader } from '@/components/custom-components/Loader';
import { ErrorDisplay } from '@/components/error';
import { Button } from '@/components/shadcn/button';
import { LandingAdCard } from '@/components/templates/LandingAdCard';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { showTemplateRecentButton } from '@/utils/data/feeds.ts';
import { PRO_PLAN } from '@/utils/data/plans';
import FreeTrialEnding from '@/components/FreeTrialEnding';
import { StartFreeTrialAdCard } from '@/components/templates/AdGridView';
import useCardLayoutWithImpressions from '@/hooks/useCardLayoutWithImpressions';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

export const Route = createFileRoute('/share/templates/emails/$adID')({
  component: SharedEmailTemplate,
});

function SharedEmailTemplate() {
  const navigate = useNavigate({ from: '/share/templates/emails/$adID' });
  const { adID } = Route.useParams();
  const { cacheBuster } = Route.useSearch();

  if (!cacheBuster) {
    navigate({
      search: (old) => {
        return {
          ...old,
          cacheBuster: Math.random(),
        };
      },
      params: (old) => {
        return {
          ...old,
        };
      },
    });
  }

  const { data: authUser, isLoading: isLoadingMe } = trpc.me.useQuery(null);
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);
  const { squareRef, columns, gutterWidth } = useCardLayoutWithImpressions();

  const {
    data: emailTemplates,
    isLoading: isLoadingEmailTemplate,
    error: emailTemplateError,
  } = trpc.getSharedEmailTemplate.useQuery({ emailTemplateId: parseInt(adID) });

  const { data: permissionData } = trpc.permissions.useQuery(null, {});

  return (
    <div className={'relative h-[97vh]'}>
      {authUser && upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <Stack className="!mb-6 gap-8">
        <Stack className="gap-3">
          <div
            className={
              'flex justify-between lg:justify-start gap-5 items-center'
            }
          >
            <Text weight="semibold" size={'xxl'}>
              Email Templates
            </Text>
            {!authUser ? (
              <div className="flex gap-2 items-center">
                <a target={'_blank'} href={'https://app.creativeos.io/login'}>
                  <Button size={'sm'} variant={'ghost'} className="flex">
                    Log In
                  </Button>
                </a>

                <a target={'_blank'} href={'https://www.creativeos.io/#sign'}>
                  <Button
                    size={'sm'}
                    variant={'destructivePurple'}
                    className="flex"
                  >
                    Get Started
                  </Button>
                </a>
              </div>
            ) : authUser &&
              !(
                permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                permissionData?.usersPlans.includes(PRO_PLAN.no_trial_plan_id)
              ) ? (
              <Button
                onClick={() => setUpgradeDialogOpen(true)}
                variant={'destructivePurple'}
                size={'sm'}
              >
                Upgrade
              </Button>
            ) : (
              <></>
            )}
          </div>
          <Text
            className="text-thememutedforeground w-fit"
            size={'base'}
            weight={'normal'}
          >
            Somebody shared an email template with you!
            {!authUser
              ? ` Sign up for Creative OS Pro Plan to access this and our full library of Ad, Email, and Landing Page Templates, and so much more `
              : !(
                    permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                    permissionData?.usersPlans.includes(
                      PRO_PLAN.no_trial_plan_id,
                    )
                  )
                ? ' Upgrade to Creative OS Pro to access our full library of Email and Landing Page Templates.'
                : ''}
            {!authUser && (
              <a
                className="text-black underline"
                target={'_blank'}
                href="https://www.creativeos.io/"
              >
                Learn more about Creative OS.
              </a>
            )}
          </Text>
        </Stack>

        {isLoadingEmailTemplate || isLoadingMe ? (
          <div
            className={
              'flex justify-center items-center w-full h-screen m-auto'
            }
          >
            <Loader />
          </div>
        ) : emailTemplateError ? (
          <ErrorDisplay />
        ) : (
          emailTemplates && (
            <div ref={squareRef} className={'relative w-full pb-10'}>
              <div>
                <ResponsiveMasonry
                  columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
                >
                  <Masonry gutter={gutterWidth / 16 + 'rem'}>
                    {[
                      emailTemplates[0] && (
                        <LandingAdCard
                          IsShared
                          key={emailTemplates[0]['Email ID']}
                          adData={{
                            Screenshot: emailTemplates[0]['Email Screenshot'],
                            ID: emailTemplates[0]['Email ID'].toString(),
                            created: emailTemplates[0].Created,
                            category: emailTemplates[0].Category,
                            isSaved: emailTemplates[0].isSaved,
                            imageUrl: emailTemplates[0].imageUrl,
                          }}
                          openDetailsOnInit={
                            permissionData &&
                            authUser &&
                            (permissionData.usersPlans.includes(
                              PRO_PLAN.plan_id,
                            ) ||
                              permissionData.usersPlans.includes(
                                PRO_PLAN.no_trial_plan_id,
                              ))
                          }
                          IsLocked={
                            !(
                              permissionData &&
                              authUser &&
                              (permissionData.usersPlans.includes(
                                PRO_PLAN.plan_id,
                              ) ||
                                permissionData.usersPlans.includes(
                                  PRO_PLAN.no_trial_plan_id,
                                ))
                            )
                          }
                          type={'email'}
                        />
                      ),
                      ...(!(
                        permissionData &&
                        (permissionData.usersPlans.includes(PRO_PLAN.plan_id) ||
                          permissionData.usersPlans.includes(
                            PRO_PLAN.no_trial_plan_id,
                          ))
                      )
                        ? [
                            <StartFreeTrialAdCard
                              key="startFreeTrial"
                              fullWidthButton
                              titleText={
                                !authUser ? undefined : 'Upgrade to Unlock'
                              }
                              bodyText={
                                !authUser
                                  ? undefined
                                  : 'Level up your ad workflow with Creative OS Pro'
                              }
                              buttonText={
                                !authUser ? 'Start Free Trial' : 'Upgrade Now'
                              }
                              href={
                                !authUser
                                  ? 'https://www.creativeos.io/#sign'
                                  : undefined
                              }
                              onClick={
                                authUser
                                  ? () => setUpgradeDialogOpen(true)
                                  : undefined
                              }
                            />,
                          ]
                        : []),
                      ...emailTemplates.slice(1).map((emailTemplate) => (
                        <LandingAdCard
                          key={emailTemplate['Email ID']}
                          adData={{
                            Screenshot: emailTemplate['Email Screenshot'],
                            ID: emailTemplate['Email ID'].toString(),
                            created: emailTemplate.Created,
                            category: emailTemplate.Category,
                            isSaved: emailTemplate.isSaved,
                            imageUrl: emailTemplate.imageUrl,
                          }}
                          showRecentButton={showTemplateRecentButton(
                            emailTemplate.Created,
                          )}
                          IsLocked={
                            !(
                              permissionData &&
                              authUser &&
                              (permissionData.usersPlans.includes(
                                PRO_PLAN.plan_id,
                              ) ||
                                permissionData.usersPlans.includes(
                                  PRO_PLAN.no_trial_plan_id,
                                ))
                            )
                          }
                          type={'email'}
                        />
                      )),
                    ]}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            </div>
          )
        )}
      </Stack>
    </div>
  );
}

import { trpc } from "@/utils/trpc.ts";
import { useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/shadcn/dialog.tsx";
import { Stack, Text } from "@/components/custom-components";
import { CheckCircle2 } from "lucide-react";
import { Loading } from "@/components/loading.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import { BoardProp } from "@/components/templates/Sidebar";

export const DeleteBoardDialog = ({
  data,
  open,
  onOpenChange,
}: {
  data?: BoardProp;
  open: boolean;
  onOpenChange: () => void;
}) => {
  const utils = trpc.useUtils();

  const [message, setMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const { mutateAsync: deleteBoard, isPending: deleteIsPending } =
    trpc.deleteBoard.useMutation({
      onSuccess: async () => {
        setMessage("Collection deleted successfully");
        await utils.getBoards
          .invalidate(undefined, { refetchType: "all" })
          .then(() => {
            setTimeout(() => {
              onOpenChange();
              navigate({
                to: `/feeds/boards`,
              });
            }, 2000);
          });
      },
    });

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className={"gap-1.5"}>
        <DialogHeader>
          <DialogTitle>
            <Text weight={"semibold"} size={"lg"}>
              Delete Collection
            </Text>
          </DialogTitle>
        </DialogHeader>
        {message ? (
          <div
            className={
              "flex flex-col items-center text-center gap-2 w-full h-full m-auto"
            }
          >
            <CheckCircle2 size={"3rem"} strokeWidth={"1.2"} color={"green"} />
            <p>{message}</p>
          </div>
        ) : deleteIsPending ? (
          <div className={"flex w-full h-full m-auto"}>
            <Loading />
          </div>
        ) : (
          data && (
            <Stack className={"gap-4"}>
              <Text
                align={"left"}
                weight={"normal"}
                size={"sm"}
                className="leading-5 text-thememutedforeground"
              >
                Are you sure you want to delete the collection {data?.name}
              </Text>
              <div className={"flex justify-end gap-2"}>
                <Button
                  disabled={deleteIsPending}
                  className={"w-15 px-3 h-8 text-sm"}
                  variant={"outline"}
                  onClick={onOpenChange}
                >
                  No
                </Button>
                <Button
                  disabled={deleteIsPending}
                  onClick={() => deleteBoard({ boardId: data.id })}
                  className={"w-15 px-3 h-8 text-sm"}
                  variant={"default"}
                >
                  Yes
                </Button>
              </div>
            </Stack>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};

import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Tags } from "../../../../src/shared/airtable";

import { StartFreeTrialAdCard } from "@/components/templates/AdGridView";
import { Stack, Text } from "@/components/custom-components";
import { Button } from "@/components/shadcn/button.tsx";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { trpc } from "@/App.tsx";
import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";
import { PRO_PLAN } from "@/utils/data/plans.ts";
import { useState } from "react";
import { Loader } from "@/components/custom-components/Loader/index.tsx";
import { ErrorDisplay } from "@/components/error.tsx";
import useCardLayoutWithImpressions from "@/hooks/useCardLayoutWithImpressions.tsx";
import { LandingFeedAdCard } from "@/components/templates/LandingAdCard/index.tsx";
type SearchParams = {
  Tags?: Tags;
};

export const Route = createFileRoute("/share/inspiration/landing-pages/$adID")({
  component: SharedAd,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const tag = search?.Tags as Tags | undefined;
    return {
      Tags: tag,
    };
  },
});

function SharedAd() {
  const navigate = useNavigate({
    from: "/share/inspiration/landing-pages/$adID",
  });
  const { adID } = Route.useParams();
  const { cacheBuster } = Route.useSearch();
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);
  const { squareRef, columns, gutterWidth } = useCardLayoutWithImpressions();

  const { data: permissionData } = trpc.permissions.useQuery(null, {});
  const { data: authUser, isLoading: isLoadingMe } = trpc.me.useQuery(null);
  const {
    data: adInspirations,
    isError,
    isLoading,
  } = trpc.previewLanderInFeed.useQuery({
    landerId: parseInt(adID),
  });
  if (!cacheBuster) {
    navigate({
      search: (old) => {
        return {
          ...old,
          cacheBuster: Math.random(),
        };
      },
      params: (old) => {
        return {
          ...old,
        };
      },
    });
  }
  return (
    <div className={"relative h-[97vh]"}>
      {authUser && upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <Stack className="!mb-6 gap-8">
        <Stack className="gap-3">
          <div
            className={
              "flex justify-between lg:justify-start gap-5 items-center"
            }
          >
            <Text weight="semibold" size={"xxl"}>
              Get Inspired
            </Text>
            {!authUser ? (
              <div className="flex gap-2 items-center">
                <a target={"_blank"} href={"https://app.creativeos.io/login"}>
                  <Button size={"sm"} variant={"ghost"} className="flex">
                    Log In
                  </Button>
                </a>

                <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
                  <Button
                    size={"sm"}
                    variant={"destructivePurple"}
                    className="flex"
                  >
                    Get Started
                  </Button>
                </a>
              </div>
            ) : authUser &&
              !(
                permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                permissionData?.usersPlans.includes(PRO_PLAN.no_trial_plan_id)
              ) ? (
              <Button
                onClick={() => setUpgradeDialogOpen(true)}
                variant={"destructivePurple"}
                size={"sm"}
              >
                Upgrade
              </Button>
            ) : (
              <></>
            )}
          </div>
          <Text
            className="text-thememutedforeground w-fit"
            size={"base"}
            weight={"normal"}
          >
            Somebody shared an ad with you!
            {!authUser
              ? ` Sign up for Creative OS to access our full library of Ad, Email, and Landing Page Templates, and so much more. `
              : !(
                    permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
                    permissionData?.usersPlans.includes(
                      PRO_PLAN.no_trial_plan_id
                    )
                  )
                ? " Upgrade to Creative OS Pro to access our full library of Email and Landing Page Templates."
                : ""}
            {!authUser && (
              <a
                className="text-black underline"
                target={"_blank"}
                href="https://www.creativeos.io/"
              >
                Learn more about Creative OS.
              </a>
            )}
          </Text>
        </Stack>

        {isLoading || isLoadingMe ? (
          <div
            className={
              "flex justify-center items-center w-full h-screen m-auto"
            }
          >
            <Loader />
          </div>
        ) : isError ? (
          <ErrorDisplay />
        ) : (
          adInspirations && (
            <div ref={squareRef} className={"relative w-full pb-10"}>
              <div>
                <ResponsiveMasonry
                  columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
                >
                  <Masonry gutter={gutterWidth / 16 + "rem"}>
                    {[
                      adInspirations[0] && (
                        <LandingFeedAdCard
                          key={adInspirations[0].landerId}
                          adData={adInspirations[0]}
                          IsPublic={true}
                          IsLocked={!authUser}
                          openDetailsOnInit={authUser ? true : false}
                          IsShared={true}
                        />
                      ),
                      ...(!authUser
                        ? [
                            <StartFreeTrialAdCard
                              key="startFreeTrial"
                              fullWidthButton
                              titleText={
                                !authUser ? undefined : "Upgrade to Unlock"
                              }
                              bodyText={
                                !authUser
                                  ? undefined
                                  : "Level up your ad workflow with Creative OS Pro"
                              }
                              buttonText={
                                !authUser ? "Start Free Trial" : "Upgrade Now"
                              }
                              href={
                                !authUser
                                  ? "https://www.creativeos.io/#sign"
                                  : undefined
                              }
                              onClick={
                                authUser
                                  ? () => setUpgradeDialogOpen(true)
                                  : undefined
                              }
                            />,
                          ]
                        : []),
                      ...adInspirations
                        .slice(1)
                        .map((landerInspiration) => (
                          <LandingFeedAdCard
                            key={landerInspiration.landerId}
                            adData={landerInspiration}
                            IsPublic={true}
                            IsLocked={!authUser}
                          />
                        )),
                    ]}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            </div>
          )
        )}
      </Stack>
    </div>
  );
}

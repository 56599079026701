import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/shadcn/form";
import { PasswordInput } from "@/components/shadcn/password-input";
import { Input } from "@/components/shadcn/input";
import { Button } from "@/components/shadcn/button";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Label } from "@/components/shadcn/label.tsx";
import OnboardingHeader from "@/components/onboarding/OnboardingHeader.tsx";
import { trpc } from "@/utils/trpc.ts";
import showToastNotification from "@/hooks/useShowToast";
import { useEffect, useState } from "react";
import usePasswordStrengthCheck from "@/hooks/usePasswordStrengthCheck.tsx";
import { Turnstile } from "@marsidev/react-turnstile";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { featureFlagKeys } from "@/utils/data/featureFlags.ts";

export function isMobileDeviceMetaBrowser() {
  // User-Agent detection for common mobile platforms
  const mobileUserAgentRegex =
    /Mobi|Android|iPhone|iPad|iPod|BlackBerry|Windows Phone|Opera Mini|IEMobile/i;

  // Feature detection (touchscreen)
  const hasTouchScreen =
    "ontouchstart" in window || navigator.maxTouchPoints > 0;

  // Screen size detection
  const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
  // check if it is facebook or instagrams browser
  const ua = navigator.userAgent;
  const metaBrowserTags = ["FBAN", "FBAV", "Instagram"];

  // Combine checks for robustness
  return (
    (mobileUserAgentRegex.test(ua) || (hasTouchScreen && isSmallScreen)) &&
    metaBrowserTags.some((tag) => ua.includes(tag))
  );
}
import { Checkbox } from "@/components/shadcn/checkbox";
import { Text } from "@/components/custom-components";

type SearchParams = {
  plan?: string;
  coupon?: string;
};

export const Route = createFileRoute("/sign-up")({
  component: SignUp,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const plan = search?.plan as string | undefined;
    const coupon = search?.coupon as string | undefined;

    return {
      plan,
      coupon,
    };
  },
});

function SignUp() {
  const { plan, coupon } = Route.useSearch();
  const navigate = useNavigate();
  const [captchaStatus, setCaptchaStatus] = useState<
    "error" | "expired" | "solved" | null
  >(null);
  const [captchaToken, setCaptchaToken] = useState<string | undefined>(
    undefined,
  );
  const captchaFlagEnabled = useFeatureFlagEnabled(featureFlagKeys.captcha);

  const {
    passwordColor,
    passwordStrengthLabel,
    passwordStrength,
    handlePasswordChange,
  } = usePasswordStrengthCheck();

  useEffect(() => {
    if (plan) {
      localStorage.setItem("cos_plan", plan);
    }
    if (coupon) {
      localStorage.setItem("cos_coupon", coupon);
    }
  }, []);

  const { mutate: signUpWithEmailAndPasswordWorkOs, isPending } =
    trpc.signUpWithEmailAndPassword.useMutation();

  const { mutate: signUpWithGoogle, isPending: isPendingGoogleUrl } =
    trpc.generateGoogleAuthorizationUrl.useMutation();

  const { mutateAsync: logoutUser } = trpc.logoutUser.useMutation();

  const signupFormSchema = z
    .object({
      firstName: z.string({ required_error: "First Name is required" }),
      lastName: z.string({ required_error: "Last Name is required" }),
      email: z.string({ required_error: "Email address is required" }).email({
        message: "Please enter a valid email address.",
      }),
      password: z
        .string({ required_error: "Password is required" })
        .min(8, {
          message: "Password must be at least 8 characters.",
        })
        .regex(/[a-z]/, {
          message: "Password must contain at least one lowercase letter.",
        })
        .regex(/[A-Z]/, {
          message: "Password must contain at least one uppercase letter.",
        })
        .regex(/\d/, { message: "Password must contain at least one number." }),
      newsletter: z.boolean(),
    })
    .refine((data) => data.password !== data.email, {
      message: "Password cannot be the same as your email",
      path: ["password"],
    });

  type SignupFormValues = z.infer<typeof signupFormSchema>;

  const defaultValues: Partial<SignupFormValues> = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    newsletter: true,
  };

  const form = useForm<SignupFormValues>({
    resolver: zodResolver(signupFormSchema),
    defaultValues,
  });

  async function onSubmit({
    firstName,
    lastName,
    email,
    password,
  }: SignupFormValues) {
    const signupWithEmailAndPasswordOpts = {
      onSuccess: (data: unknown) => {
        if (data) {
          localStorage.setItem(
            "_wos_user",
            JSON.stringify({
              ...data,
              newsletter: form.getValues("newsletter"),
            })
          );
          showToastNotification("success", {
            message: "Account created successfully!",
          });
          navigate({ to: "/verify-email" });
        }
      },
      onError: (error: { message: string }) => {
        showToastNotification("error", {
          message: error.message,
        });
      },
    };
    logoutUser(undefined, {
      onSuccess: () => {
        if (captchaStatus === "solved") {
          signUpWithEmailAndPasswordWorkOs(
            { firstName, lastName, email, password, plan, captchaToken },
            signupWithEmailAndPasswordOpts,
          );
        } else
          showToastNotification("error", {
            message: "CAPTCHA Verification Failed",
            description:
              "Please complete the CAPTCHA to proceed. If the issue persists, refresh the page and try again.",
          });
      },
      onError: () => {
        if (captchaStatus === "solved")
          signUpWithEmailAndPasswordWorkOs(
            { firstName, lastName, email, password, plan, captchaToken },
            signupWithEmailAndPasswordOpts,
          );
        else
          showToastNotification("error", {
            message: "CAPTCHA Verification Failed",
            description:
              "Please complete the CAPTCHA to proceed. If the issue persists, refresh the page and try again.",
          });
      },
    });
  }

  const signUpWithGoogleAuth = async () => {
    const signUpWithGoogleOpts = {
      onSuccess: (data: string) => {
        if (data) {
          window.location.href = data;
        }
      },
      onError: (error: { message: string }) => {
        showToastNotification("error", {
          message: error.message,
        });
      },
    };
    logoutUser(undefined, {
      onSuccess: () => {
        signUpWithGoogle(undefined, signUpWithGoogleOpts);
      },
      onError: () => {
        signUpWithGoogle(undefined, signUpWithGoogleOpts);
      },
    });
  };

  return (
    <div
      className={"bg-brandgrad bg-no-repeat bg-center bg-cover min-h-screen"}
    >
      <div>
        <OnboardingHeader />
      </div>
      <div
        className={
          "flex-1 flex flex-col justify-center items-center py-[7.75rem]"
        }
      >
        <div
          className={
            "rounded-lg bg-white p-5 w-11/12 lg:w-[32rem] flex flex-col gap-6 border"
          }
        >
          <div className={"flex flex-col items-center gap-2"}>
            <img
              alt={"Creative OS"}
              src={"cos-logo.png"}
              className={"w-8 h-8"}
            />
            <h4
              className={
                "text-2xl text-center text-themeforeground font-semibold"
              }
            >
              Create an Account
            </h4>
          </div>
          {!isMobileDeviceMetaBrowser() && (
            <div>
              <Button
                variant={"outline"}
                type={"submit"}
                disabled={isPendingGoogleUrl}
                onClick={signUpWithGoogleAuth}
                className={"flex gap-2 items-center justify-center w-full"}
              >
                <img alt={""} src={"/images/google-icon.png"} />
                <span>Sign Up with Google</span>
              </Button>
              <div className={"flex items-center self-stretch gap-3 mt-6"}>
                <span className={"border border-themeborder w-full"} />
                <span>OR</span>
                <span className={"border border-themeborder w-full"} />
              </div>
            </div>
          )}
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <div className={"flex flex-col gap-6"}>
                 <div className={"grid lg:grid-cols-2 gap-6"}>
                   <FormField
                     control={form.control}
                     name="firstName"
                     render={({ field }) => (
                       <FormItem>
                         <Label>First Name</Label>
                         <FormControl>
                           <Input placeholder="First Name" {...field} />
                         </FormControl>
                         <FormMessage />
                       </FormItem>
                     )}
                   />
                   <FormField
                     control={form.control}
                     name="lastName"
                     render={({ field }) => (
                       <FormItem>
                         <Label>Last Name</Label>
                         <FormControl>
                           <Input placeholder="Last Name" {...field} />
                         </FormControl>
                         <FormMessage />
                       </FormItem>
                     )}
                   />
                 </div>
                 <FormField
                   control={form.control}
                   name="email"
                   render={({ field }) => (
                     <FormItem>
                       <Label>Email Address</Label>
                       <FormControl>
                         <Input
                           placeholder="name@example.com"
                           type={"email"}
                           {...field}
                         />
                       </FormControl>
                       <FormMessage />
                     </FormItem>
                   )}
                 />
                 <FormField
                   control={form.control}
                   name="password"
                   render={({ field }) => (
                     <FormItem>
                       <Label>Password</Label>
                       <FormControl>
                         <PasswordInput
                           placeholder="Password"
                           {...field}
                           onChange={(e) => {
                             handlePasswordChange(e);
                             field.onChange(e);
                           }}
                         />
                       </FormControl>
                       {form.getValues("password") && (
                         <div className="text-xs mt-1">
                           Password Strength:{" "}
                           <span
                             style={{ color: passwordColor }}
                             className={`font-bold ${passwordColor ? `text-[${passwordColor}]` : ""}`}
                           >
                             {passwordStrengthLabel}
                           </span>
                         </div>
                       )}
                       <FormMessage />
                     </FormItem>
                   )}
                 />
                <FormField
                  control={form.control}
                  name="newsletter"
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex gap-2 items-center">
                        <FormControl>
                          <Checkbox
                            checked={field.value}
                            onCheckedChange={(checked) => {
                              field.onChange(checked);
                            }}
                          />
                        </FormControl>
                        <Text weight={"medium"} size={"sm"}>
                          Receive useful tips, newsletters, and promotions via
                          e-mail
                        </Text>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div>
                <Button
                  disabled={isPending || passwordStrength < 2}
                  type={"submit"}
                  className="w-full"
                  loading={isPending}
                >
                  {isPending ? "Creating an account..." : "Signup"}
                </Button>
                <div>
                  <Link
                    to={"/login"}
                    className={"block text-center mt-1.5 text-sm"}
                  >
                    Already have an account?{" "}
                    <span className={"underline font-medium"}>Sign in</span>
                  </Link>
                </div>
              </div>
              {captchaFlagEnabled && (
                <div className={"flex justify-center"}>
                  <Turnstile
                    siteKey={import.meta.env.VITE_CAPTCHA_SITE_KEY}
                    onError={() => setCaptchaStatus("error")}
                    onExpire={() => setCaptchaStatus("expired")}
                    onSuccess={(token) => {
                      setCaptchaToken(token);
                      setCaptchaStatus("solved");
                    }}
                  />
                </div>
              )}
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}

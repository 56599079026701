import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { DiscoverAdCard } from "@/components/templates/AdCard";
import { z } from "zod";
import { useNavigate } from "@tanstack/react-router";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { GetAdArgs } from "../../../../../../src/server/rpc";
import { Loader } from "@/components/custom-components/Loader";
import useCardLayoutWithImpressions from "@/hooks/useCardLayoutWithImpressions.tsx";
import { StartFreeTrialAdCard } from "../AdGridView";

export type Ad = {
  id: number;
  reach: number | null;
  earliestView: string;
  adRunningDays: number;
  imageUrl: string | null;
  brandId: string | null;
  brandName: string | null;
  brandImage: string | null;
  videoUrl: string | null;
  hasUserSeenAd?: boolean;
  isActiveSinceLastScrape?: boolean;
  isSaved: boolean;
  rank?: number;
  inactiveTime?: number;
  numberOfRequests?: number;
  requestCount?: number;
  latestLaunch?: string;
  ctaText?: string | null | undefined;
};

export type Brand = {
  brandName: string;
  brandImage: string;
  ads: Ad[];
};

export const DiscoverGridView = ({
  data,
  scrollRef,
  isRefetching,
}: {
  isRefetching?: boolean;
  data: Ad[];
  scrollRef?: (node?: Element | null | undefined) => void;
}) => {
  const { squareRef, desiredCardWidth, columns, gutterWidth, addPostToBatch } =
    useCardLayoutWithImpressions();

  return (
    <div>
      <div ref={squareRef} className={"relative w-full pb-10"}>
        {data && (
          <div>
            <ResponsiveMasonry
              columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
            >
              <Masonry gutter={gutterWidth / 16 + "rem"}>
                {data
                  .filter(
                    (item, index) =>
                      index === data.findIndex((obj) => obj.id === item.id),
                  )
                  .map((ad) => (
                    <DiscoverAdCard
                      key={ad.id}
                      DesiredWidth={desiredCardWidth}
                      adData={ad}
                      IsPublic={false}
                      IsLocked={false}
                      onView={addPostToBatch}
                    />
                  ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        )}
        <div className={"relative"}>
          <div
            className={
              "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
            }
            ref={scrollRef}
          >
            {isRefetching && (
              <div className={"flex justify-center items-center w-full m-auto"}>
                <Loader />
              </div>
            )}
          </div>
        </div>
        <div className={"relative"}>
          <div
            className={
              "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
            }
            ref={scrollRef}
          >
            {isRefetching && (
              <div className={"flex justify-center items-center w-full m-auto"}>
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

type publicProps = z.infer<typeof GetAdArgs>;

export const DiscoverGridViewPublic = (props: publicProps) => {
  // If a user is logged in, they don't need to see this screen
  const { data: authUser } = trpc.me.useQuery(null);

  const navigate = useNavigate();
  if (authUser) {
    navigate({
      to: "/feeds/inspiration/$adID",
      params: {
        adID: props.adId.toString(),
      },
    });
  }

  const { squareRef, desiredCardWidth, columns, gutterWidth, addPostToBatch } =
    useCardLayoutWithImpressions();

  const { data, isError, isLoading, isRefetching } =
    trpc.previewAdInFeed.useQuery(
      {
        adId: props.adId,
      },
      {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    );

  if (isError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (!data || isLoading || isRefetching) {
    return (
      <div
        className={"flex w-full justify-center items-center h-[70vh] m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div ref={squareRef} className={"relative w-full px-0 md:px-0 pb-10"}>
      {data && (
        <div>
          <ResponsiveMasonry
            columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
          >
            <Masonry gutter={gutterWidth / 16 + "rem"}>
              {[
                data[0] && (
                  <DiscoverAdCard
                    key={data[0].id}
                    DesiredWidth={desiredCardWidth}
                    adData={data[0]}
                    IsPublic={true}
                    IsLocked={false}
                    onView={addPostToBatch}
                  />
                ),
                <StartFreeTrialAdCard key="startFreeTrial" />,
                ...data
                  .slice(1)
                  .map((ad) => (
                    <DiscoverAdCard
                      key={ad.id}
                      DesiredWidth={desiredCardWidth}
                      adData={ad}
                      IsPublic={true}
                      IsLocked={true}
                      onView={addPostToBatch}
                    />
                  )),
              ]}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      )}
    </div>
  );
};

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/shadcn/table.tsx";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/shadcn/tabs.tsx";
import { ArrowRight, Check, Layout } from "@mynaui/icons-react";
import { Mail, MessageSquare } from "lucide-react";
import { Progress } from "@/components/shadcn/progress.tsx";
import { Badge } from "@/components/shadcn/badge.tsx";

export type ScoreType = {
  score: string;
  max: number;
  min: number;
  grade: string;
  summary: string;
};

export interface PersuasionElement {
  name: string;
  score: number;
  keywords: string[];
}

export type PersuasionType = {
  score: number;
  elements: PersuasionElement[];
  strengths: string[];
  suggestions: string[];
  contextualTips: string[];
};

export type AnalysisResults = {
  readability: {
    score: number;
    metrics: {
      characters: number;
      words: number;
      sentences: number;
      syllables: number;
    };
    grade: string;
  };
  persuasion: {
    score: number;
    elements: PersuasionElement[];
    strengths: string[];
    suggestions: string[];
    contextualTips: string[];
  };
};

export interface PersuasionLevel {
  scoreRange: string;
  level: string;
  description: string;
}

// Array of persuasion levels
export const persuasionData: PersuasionLevel[] = [
  {
    scoreRange: "90-100",
    level: "Exceptional",
    description:
      "All core elements with strong emotional triggers and clear value proposition",
  },
  {
    scoreRange: "80-89",
    level: "Very Persuasive",
    description:
      "Most elements with compelling hooks and psychological triggers",
  },
  {
    scoreRange: "70-79",
    level: "Effectively Persuasive",
    description: "Core elements present but may lack emotional depth",
  },
  {
    scoreRange: "60-69",
    level: "Moderately Persuasive",
    description: "Basic persuasion elements but needs strengthening",
  },
  {
    scoreRange: "40-59",
    level: "Basic Persuasion",
    description: "Missing key elements or weak implementation",
  },
  {
    scoreRange: "0-39",
    level: "Needs Improvement",
    description: "Lacks most persuasive elements",
  },
];

export const reabilityscores: ScoreType[] = [
  {
    score: "90 - 100",
    max: 100,
    min: 90,
    grade: "5th Grade",
    summary: "Very easy to read",
  },
  {
    score: "80 - 90",
    max: 90,
    min: 80,
    grade: "6th Grade",
    summary: "Easy to read",
  },
  {
    score: "70 - 80",
    max: 80,
    min: 70,
    grade: "7th Grade (U.S Average Reading Level)",
    summary: "Fairly easy to read",
  },
  {
    score: "60 - 70",
    max: 70,
    min: 60,
    grade: "8th - 9th Grade",
    summary: "Plain English",
  },
  {
    score: "50 - 60",
    max: 60,
    min: 50,
    grade: "10th - 12th Grade",
    summary: "Fairly difficult to read",
  },
  {
    score: "30 - 50",
    max: 50,
    min: 30,
    grade: "College",
    summary: "Difficult to read",
  },
  {
    score: "0 - 30",
    max: 30,
    min: 0,
    grade: "College Graduate",
    summary: "Very difficult to read",
  },
];

export const contentTypes = {
  email: {
    subtypes: ["promotional", "newsletter", "transactional", "abandoned-cart"],
    title: "Email Campaign",
    icon: Mail,
  },
  landing: {
    subtypes: ["product", "service", "signup", "promotional"],
    title: "Landing Page",
    icon: Layout,
  },
  ad: {
    subtypes: ["display", "search", "social", "video"],
    title: "Advertisement",
    icon: MessageSquare,
  },
};

export const PERSUASION_ELEMENTS = {
  scarcity: {
    weight: 15,
    keywords: [
      "limited",
      "only",
      "few",
      "running out",
      "last chance",
      "before they're gone",
    ],
    multiplier: 1.2,
  },
  urgency: {
    weight: 15,
    keywords: ["now", "today", "soon", "hurry", "quick", "fast", "immediate"],
    multiplier: 1.2,
  },
  socialProof: {
    weight: 12,
    keywords: [
      "best seller",
      "popular",
      "trusted",
      "recommended",
      "reviews",
      "rating",
    ],
    multiplier: 1.1,
  },
  benefits: {
    weight: 15,
    keywords: ["you", "your", "benefit", "gain", "improve", "better"],
    multiplier: 1.15,
  },
  callToAction: {
    weight: 13,
    keywords: ["get", "buy", "shop", "start", "try", "learn", "discover"],
    multiplier: 1.1,
  },
};

export function ReadabilityScore({
  data,
  fleschScore,
}: {
  data: ScoreType[];
  fleschScore: number;
}) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-fit">Score</TableHead>
          <TableHead className="w-fit">Reading Grade Level</TableHead>
          <TableHead>Summary</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((data) => {
          let rowBackgroundColor: string = "";

          // Highlight only the row with the matching min score range
          if (fleschScore < 0 && data.min === 0) {
            rowBackgroundColor = "bg-maroon-100";
          } else if (
            (fleschScore >= 90 && data.min === 90) ||
            (fleschScore >= 80 && fleschScore <= 89 && data.min === 80)
          ) {
            rowBackgroundColor = "bg-green-50"; // Green for 80 and above
          } else if (
            (fleschScore >= 70 && fleschScore <= 79 && data.min === 70) ||
            (fleschScore >= 60 && fleschScore <= 69 && data.min === 60)
          ) {
            rowBackgroundColor = "bg-yellow-50"; // Yellow for 60-79
          } else if (
            (fleschScore >= 30 && fleschScore <= 49 && data.min === 30) ||
            (fleschScore >= 50 && fleschScore <= 59 && data.min === 50)
          ) {
            rowBackgroundColor = "bg-maroon-50"; // Maroon for 30-59
          } else if (fleschScore <= 29 && fleschScore !== 0 && data.min === 0) {
            rowBackgroundColor = "bg-maroon-100"; // Dark Maroon for 0-29
          }

          return (
            <TableRow
              key={data.score}
              className={`${rowBackgroundColor} transition-none`}
            >
              <TableCell className="font-medium w-[5rem] md:w-auto">
                {data.score}
              </TableCell>
              <TableCell className={"text-thememutedforeground"}>
                {data.grade}
              </TableCell>
              <TableCell className={"text-thememutedforeground"}>
                {data.summary}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export const PersuasionScoreTabs = ({
  analysisResults,
}: {
  analysisResults: PersuasionType;
}) => {
  return (
    <Tabs defaultValue="elements">
      <div className={"flex justify-center "}>
        <TabsList className="mb-6 rounded-full">
          <TabsTrigger className={"rounded-full"} value="elements">
            Elements
          </TabsTrigger>
          <TabsTrigger className={"rounded-full"} value="suggestions">
            Suggestions
          </TabsTrigger>
          <TabsTrigger className={"rounded-full"} value="guidelines">
            Guidelines
          </TabsTrigger>
        </TabsList>
      </div>
      <TabsContent value="elements">
        <div className="space-y-4">
          {analysisResults.elements.map((element, index) => (
            <div key={index} className="space-y-2">
              <div className="flex items-center justify-between text-sm">
                <span className="font-medium capitalize">{element.name}</span>
                <span className="text-sm font-light text-thememutedforeground">
                  {element.score}%
                </span>
              </div>
              <Progress value={element.score} className={`h-2 `} />
              {element.keywords.length > 0 && (
                <div className="flex flex-wrap gap-2">
                  {element.keywords.map((keyword, idx) => (
                    <Badge
                      variant={"secondary"}
                      key={idx}
                      className=" rounded-full"
                    >
                      {keyword}
                    </Badge>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </TabsContent>
      <TabsContent value="suggestions">
        <div className="space-y-6">
          <div className="space-y-4 text-sm">
            <div className="space-y-4">
              <h3 className="font-medium">Strengths</h3>
              <ul className="space-y-2 text-thememutedforeground font-light">
                {analysisResults.strengths.map((strength, idx) => (
                  <li key={idx} className="flex items-center gap-2">
                    <Check className="w-4 h-4 " />
                    <span>Includes {strength}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="space-y-4">
              <h3 className="font-medium">Improvements</h3>
              <ul className="space-y-2 text-thememutedforeground font-light">
                {analysisResults.suggestions.map((suggestion, idx) => (
                  <li key={idx} className="flex items-center gap-2">
                    <ArrowRight className="w-4 h-4 " />
                    <span>{suggestion}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </TabsContent>
      <TabsContent value="guidelines">
        <div className="space-y-4 text-sm">
          <h3 className="font-medium">Content Guidelines</h3>
          <ul className="space-y-2 text-thememutedforeground font-light">
            {analysisResults.contextualTips.map((tip, idx) => (
              <li key={idx} className="flex items-center gap-2">
                <ArrowRight className="w-4 h-4" />
                {tip}
              </li>
            ))}
          </ul>
        </div>
      </TabsContent>
    </Tabs>
  );
};

import { createRootRoute, Outlet } from "@tanstack/react-router";
import { Toaster } from "@/components/shadcn/sonner.tsx";
export const Route = createRootRoute({
  component: RootRoute,
});

function RootRoute() {
  return (
    <>
      <Outlet />
      <Toaster />
      {/*<TanStackRouterDevtools />*/}
    </>
  );
}

import { DangerTriangle, X } from "@mynaui/icons-react";
import { toast } from "sonner";


type ToastType = 'success' | 'error' | 'default' | 'warning';

interface ToastOptions {
  message: string;
  description?: string;
  duration?: number; 
}

const generateId = (): number => {
  return Date.now(); 
};

const showToastNotification = (type: ToastType, { message, description = "",duration = 5000 }: ToastOptions): void => {
  const id = generateId(); // Generating a unique ID that i can use to identify the toast and close it

  if (type === 'success') {
    toast.success( message, {
      id,
      duration,
      description,
      action: (
        <X
          onClick={() => toast.dismiss(id)}
          className="text-thememuted w-5 h-5 ml-auto hover:cursor-pointer"
        />
      ),
    });
  } 
  else if (type === 'warning') {
    toast.warning( message, {
      id,
      duration,
      description,
      descriptionClassName:'font-normal text-sm leading-5',
      className:'font-bold text-sm leading-5 z-100',
      action: (
        <X
          onClick={() => toast.dismiss(id)}
          className="text-thememuted w-5 h-5 ml-auto hover:cursor-pointer"
        />
      ),
      
      icon: <DangerTriangle className='!text-thememuted'/>
    });
  } 
  
  else if (type === 'error') {
    toast.error(message, {
      id,
      description,
      duration,
      action: (
        <X
          onClick={() => toast.dismiss(id)}
          className="text-thememuted w-5 h-5 ml-auto hover:cursor-pointer"
        />
      ),
    });
  }else{
    toast(message, {
      id,
      description,
      duration,
      action: (
        <X
          onClick={() => toast.dismiss(id)}
          className="text-thememuted w-5 h-5 ml-auto hover:cursor-pointer"
        />
      ),
    });

  }
};

export default showToastNotification;

import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from "@/components/shadcn/drawer.tsx";
import { Button, buttonVariants } from "@/components/shadcn/button.tsx";
import { Ad } from "@/components/templates/DiscoverGridView";
import { trpc } from "@/utils/trpc.ts";
import {
  BookOpen,
  Box,
  CalendarUp,
  Copy,
  InfoCircle,
  Like,
  Link as LinkIcon,
  Lock,
  TrendingDown,
  TrendingUp,
  X,
} from "@mynaui/icons-react";
import { Link } from "@tanstack/react-router";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import showToastNotification from "@/hooks/useShowToast.tsx";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import { useState } from "react";
import { ManuallySaveAdToBoard } from "@/components/templates/AdCard";
import { Clock7 } from "lucide-react";
import { cn } from "@/lib/utils.ts";
import { ScrollArea } from "@/components/shadcn/scroll-area.tsx";
import FreeAccessPrompt from "@/components/FreeAccessPrompt.tsx";
import { Ad as AdCreativeDetails } from "../../../../src/server/types";
import { Loader } from "./custom-components/Loader";
import { useCopyGrader } from "@/hooks/useCopyGrader.tsx";
import { Progress } from "@/components/shadcn/progress.tsx";
import {
  REQUEST_TEMPLATE_DESCRIPTION,
  REQUEST_TEMPLATE_MESSAGE,
} from "@/_shared/constants";

const InspirationDetailsMediaSection = ({
  adCreativeDetails,
  data,
}: {
  adCreativeDetails?: AdCreativeDetails | null;
  data: Ad;
}) => (
  <div className=" flex flex-col my-12 mx-8 items-center">
    <div
      className={
        "max-w-[23.25rem] w-full border border-themeborder bg-white rounded"
      }
    >
      <div>
        <div className={"p-3"}>
          <div className={"flex gap-5 justify-between"}>
            <div className={`flex gap-1 items-center`}>
              {data.brandImage && data.brandId && (
                <Link
                  to={`/feeds/brands/$brandID`}
                  params={{ brandID: data.brandId }}
                >
                  <img
                    src={data.brandImage}
                    alt={data.brandName || "Brand name"}
                    className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain`}
                  ></img>
                </Link>
              )}
              <div>
                {data.brandName && data.brandId && (
                  <Link
                    to={`/feeds/brands/$brandID`}
                    params={{ brandID: data.brandId }}
                  >
                    <h4 className={`truncate font-[500] text-sm`}>
                      {data.brandName}
                    </h4>
                  </Link>
                )}
              </div>
            </div>
          </div>
          {adCreativeDetails && (
            <div>
              <p className={"text-xs text-thememutedforeground truncate"}>
                {adCreativeDetails.description}
              </p>
            </div>
          )}
        </div>
      </div>
      <div>
        {data.imageUrl && (
          <img
            className="w-full"
            alt="ad display"
            loading="lazy"
            src={data.imageUrl}
          />
        )}
        {data.videoUrl && (
          <video
            className="w-full"
            muted
            loop
            controls
            // autoPlay // Ensure video autoplays
            playsInline // For mobile devices (iOS in particular)
            src={data.videoUrl}
          ></video>
        )}
      </div>
      {adCreativeDetails && (
        <div className={"px-3 pt-2 pb-3 flex gap-2"}>
          <div className={"flex-1 max-w-[80%]"}>
            <h3 className={"text-sm font-medium w-full truncate"}>
              {adCreativeDetails.headline}
            </h3>
            <div className="text-xs text-thememutedforeground line-clamp-3">
              {
                adCreativeDetails?.text
                // ?.split("\n")
                // .map((line, index) => <p key={index}>{line}</p>)
              }
            </div>
          </div>
          <div className={"w-fit"}>
            {adCreativeDetails.landingPageUrl && (
              <a
                href={adCreativeDetails.landingPageUrl}
                target={"_blank"}
                className={cn(
                  buttonVariants({
                    variant: "secondary",
                    size: "sm",
                  }),
                )}
              >
                {data.ctaText ?? "CTA"}
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  </div>
);

export default function AdInspirationDetails({
  open,
  onOpenChange,
  data,
  IsPublic,
}: {
  open: boolean;
  onOpenChange: () => void;
  data: Ad;
  IsPublic: boolean;
}) {
  const [accessPromptDialogOpen, setAccessPromptDialogOpen] =
    useState<boolean>(false);
  const [saveOpen, setSaveOpen] = useState<boolean>(false);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const [openReadabilityTooltip, setOpenReadabilityTooltip] =
    useState<boolean>(false);
  const [openPersuasionTooltip, setOpenPersuasionTooltip] =
    useState<boolean>(false);
  const [, copyToClipboard] = useCopyToClipboard();
  const [copiedText, setCopiedText] = useState<boolean>(false);

  const { data: adCreativeDetails } = trpc.getAdCreativeDetails.useQuery(
    { adId: data.id },
    {
      enabled: open,
    },
  );

  const copyText = `${adCreativeDetails?.headline ?? ""}\n${adCreativeDetails?.description ?? ""}\n${adCreativeDetails?.text ?? ""}\n${adCreativeDetails?.ctaText ?? ""}`;

  const {
    readabilityScore,
    persuasionAnalysisResults,
    getReadingLevel,
    getPersuasionLevel,
  } = useCopyGrader({
    copyText,
  });
  const utils = trpc.useUtils();
  const { data: numberOfActiveAdsForBrand } =
    trpc.getNumberOfActiveAdsForBrand.useQuery(
      { brandId: data.brandId || "" },
      {
        enabled: open,
      },
    );

  const { data: authUser } = trpc.me.useQuery(null, {
    refetchOnWindowFocus: false,
  });

  const [adRequested, setAdRequested] = useState<boolean>(false);

  const { mutateAsync: requestAdTemplate } =
    trpc.requestAdTemplateRpc.useMutation();

  const {
    data: adDetails,
    refetch,
    isLoading,
    isRefetching,
  } = trpc.getAdCreativeDetails.useQuery(
    {
      adId: data.id,
    },
    {
      refetchOnMount: true,
    },
  );
  const { data: permissions } = trpc.permissions.useQuery(null, {});

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      {isLoading || isRefetching ? (
        <DrawerContent className={"h-[85vh] w-full"}>
          <DrawerHeader>
            <DrawerTitle></DrawerTitle>
            <DrawerDescription></DrawerDescription>
          </DrawerHeader>
          <div className="flex flex-col justify-center items-center w-full h-[85vh]">
            <Loader />
          </div>
        </DrawerContent>
      ) : (
        <DrawerContent
          className={"h-[90vh] p-1 flex flex-col-reverse lg:flex-row w-full"}
          onInteractOutside={saveOpen ? (e) => e.preventDefault() : undefined}
        >
          <DrawerHeader>
            <DrawerTitle></DrawerTitle>
            <DrawerDescription></DrawerDescription>
          </DrawerHeader>
          {accessPromptDialogOpen && (
            <FreeAccessPrompt
              open={accessPromptDialogOpen}
              onOpenChange={() => setAccessPromptDialogOpen(false)}
            />
          )}

          <ScrollArea
            className={"bg-themeaccent rounded h-full w-full hidden lg:flex"}
          >
            <InspirationDetailsMediaSection
              adCreativeDetails={adCreativeDetails}
              data={data}
            />
          </ScrollArea>
          <div className="hidden lg:flex">
            <div className="flex flex-col rounded lg:h-full w-full lg:w-[28rem]">
              <div className={"flex justify-end"}>
                <span onClick={onOpenChange} className={"p-1 cursor-pointer"}>
                  <X />
                </span>
              </div>

              <div className="flex flex-col  px-2 lg:px-8 py-3 pt-0 lg:pb-16 lg:pt-4 gap-3 lg:gap-6 w-full">
                <div className={"flex gap-5 justify-between"}>
                  <div className={`flex gap-1 items-center`}>
                    {data.brandImage && data.brandId && (
                      <Link
                        to={`/feeds/brands/$brandID`}
                        params={{ brandID: data.brandId }}
                      >
                        <img
                          src={data.brandImage}
                          alt={data.brandName || "Brand name"}
                          className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain`}
                        ></img>
                      </Link>
                    )}
                    <div>
                      {data.brandName && data.brandId && (
                        <Link
                          to={`/feeds/brands/$brandID`}
                          params={{ brandID: data.brandId }}
                        >
                          <h4 className={`truncate font-[500] text-sm`}>
                            {data.brandName}
                          </h4>
                        </Link>
                      )}
                      <p className={"text-xs text-thememutedforeground"}>
                        Currently running{" "}
                        {numberOfActiveAdsForBrand?.numberOfActiveAds || 0} ads
                      </p>
                    </div>
                  </div>

                  <div className={"flex gap-2"}>
                    {!IsPublic && data && (
                      <ManuallySaveAdToBoard
                        open={saveOpen}
                        onOpenChange={setSaveOpen}
                        data={data}
                        variant={"outline"}
                      />
                    )}

                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          onClick={() => {
                            copyToClipboard(
                              `${window.location.origin}/share/inspiration/${data.id}`,
                            );
                            showToastNotification("success", {
                              message: "Copied!",
                            });
                          }}
                          variant={"outline"}
                          size={"icon"}
                        >
                          <LinkIcon />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Share a public link</p>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                </div>

                <div>
                  <p className={"font-semibold text-xl"}>
                    <span>{data.brandName}</span> Ad
                  </p>
                  <div
                    className={"flex gap-4 text-thememutedforeground text-sm"}
                  >
                    <div>
                      <span className={"flex items-center gap-2"}>
                        {data.isActiveSinceLastScrape ? (
                          <>
                            <span>
                              <TrendingUp className={"text-green-500"} />
                            </span>
                            <span>Active for {data.adRunningDays} Days</span>
                          </>
                        ) : (
                          <>
                            <span>
                              <TrendingDown className={"text-amber-500"} />
                            </span>
                            <span>Inactive for {data.inactiveTime} Days</span>
                          </>
                        )}
                      </span>
                    </div>
                    <div className={"flex items-center gap-1"}>
                      <span>
                        <Like className={"w-4.5"} />
                      </span>
                      <span>{adDetails?.numberOfRequests || 0} Requests</span>
                    </div>
                  </div>
                </div>

                <div
                  className={`grid grid-cols-${!data.videoUrl ? "2" : "1"} gap-3`}
                >
                  {!data.videoUrl && (
                    <Button
                      onClick={
                        !authUser
                          ? () => setAccessPromptDialogOpen(true)
                          : () => {
                              if (permissions === undefined) {
                                showToastNotification("warning", {
                                  description: REQUEST_TEMPLATE_DESCRIPTION,
                                  message: REQUEST_TEMPLATE_MESSAGE,
                                });
                              } else if (permissions.hasRequestLimitReached) {
                                showToastNotification("warning", {
                                  description: `You're only able to request ${permissions.requestLimit} templates per month on the Basic Plan. Restarts on ${permissions.limitNextUpdateAt}.`,
                                  message: "You’ve maxed out requests",
                                });
                              } else {
                                setAdRequested(true);
                                requestAdTemplate({
                                  adId: data.id,
                                }).then(() => {
                                  showToastNotification("success", {
                                    message: `${permissions.limitLeft > 0 ? permissions.limitLeft - 1 : 0} Template Requests left!`,
                                    description: `You have ${permissions.limitLeft > 0 ? permissions.limitLeft - 1 : 0} template requests left for the month.`,
                                  });
                                  utils.permissions.invalidate();
                                  refetch();

                                  setTimeout(() => {
                                    setAdRequested(false);
                                  }, 5000);
                                });
                              }
                            }
                      }
                      className={`${(permissions?.hasRequestLimitReached ?? true) ? "opacity-50" : ""}`}
                    >
                      {(permissions?.hasRequestLimitReached ?? true) ? (
                        <Lock className="w-5 h-5 mr-2" />
                      ) : (
                        <></>
                      )}
                      {adRequested ? "Requested!" : "Request Template"}
                    </Button>
                  )}
                  {adCreativeDetails?.landingPageUrl && (
                    <a
                      href={adCreativeDetails?.landingPageUrl}
                      target={"_blank"}
                      className={cn(buttonVariants({ variant: "secondary" }))}
                    >
                      View Landing Page
                    </a>
                  )}
                </div>
                {adCreativeDetails && (
                  <div className={"flex flex-col gap-4 text-sm"}>
                    <div className={"flex justify-between items-center gap-1"}>
                      <div className={"flex items-center gap-1"}>
                        {adCreativeDetails.rating > 4 && <span>🔥</span>}
                        <span className={"font-medium"}>
                          {{
                            5: "Excellent",
                            4: "Great",
                            3: "Good",
                            2: "Average",
                            1: "Low",
                          }[adCreativeDetails.rating] || ""}{" "}
                          Performance
                        </span>

                        <Tooltip open={openTooltip}>
                          <TooltipTrigger>
                            <span
                              className={"text-thememutedforeground"}
                              onClick={() => {
                                setOpenTooltip(!openTooltip);
                              }}
                              onMouseEnter={() => {
                                setOpenTooltip(!openTooltip);
                              }}
                              onMouseLeave={() => {
                                setOpenTooltip(false);
                              }}
                            >
                              <InfoCircle size={"1.2rem"} />
                            </span>
                          </TooltipTrigger>
                          <TooltipContent className="max-w-sm">
                            <p>
                              Performance Score is an estimate calculated using
                              benchmarks like Ad Longevity, Ad Spend, and
                              performance metrics
                            </p>
                          </TooltipContent>
                        </Tooltip>
                      </div>
                      {adCreativeDetails?.rating && (
                        <div className={"flex gap-1"}>
                          {[...Array(5)].map((_, index: number) => (
                            <div
                              key={index}
                              className={`rounded-full ${index < adCreativeDetails.rating ? "bg-themedestructive" : "bg-purple-100"} w-8 h-2`}
                            ></div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className={"flex justify-between items-center gap-1"}>
                      <div className={"flex items-center gap-1.5"}>
                        <span>
                          <CalendarUp size={"1.2rem"} />
                        </span>
                        <span className={"font-medium"}>Published</span>
                      </div>
                      <div>
                        <span className={"text-thememutedforeground"}>
                          {adCreativeDetails.publishedDate &&
                          !isNaN(
                            new Date(adCreativeDetails.publishedDate).getTime(),
                          )
                            ? new Date(
                                adCreativeDetails.publishedDate,
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })
                            : ""}
                        </span>
                      </div>
                    </div>
                    <div className={"flex justify-between items-center gap-1"}>
                      <div className={"flex items-center gap-1.5"}>
                        <span>
                          <Clock7 size={"1.2rem"} />
                        </span>
                        <span className={"font-medium"}>Run Time</span>
                      </div>
                      <div>
                        <span className={"text-thememutedforeground"}>
                          {data.isActiveSinceLastScrape ? (
                            <>{data.adRunningDays} Days and still running</>
                          ) : (
                            <>Inactive for {data.inactiveTime} Days</>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className={"pt-4 border-t space-y-4"}>
                      <h4 className={"font-semibold"}>Ad Copy</h4>
                      <div className={"border rounded"}>
                        <ScrollArea className={"p-2 h-[10svh] relative"}>
                          <div
                            className={
                              "flex justify-end absolute top-1 right-1"
                            }
                          >
                            <Button
                              onClick={() => {
                                copyToClipboard(copyText).then(() => {
                                  setCopiedText(true);
                                  showToastNotification("success", {
                                    message: "Copied!",
                                  });
                                  setTimeout(() => {
                                    setCopiedText(false);
                                  }, 2000);
                                });
                              }}
                              variant={"outline"}
                              size={"sm"}
                              className={"flex gap-1.5 items-center"}
                            >
                              <span className={"text-sm font-light"}>
                                {copiedText ? "Copied!" : "Copy"}
                              </span>
                              <Copy strokeWidth={1} size={"1.2rem"} />
                            </Button>
                          </div>
                          <p className={"text-thememutedforeground"}>
                            {adCreativeDetails.headline}
                          </p>
                          <p className={"text-thememutedforeground"}>
                            {adCreativeDetails.description}
                          </p>
                          <p className={"text-thememutedforeground"}>
                            {adCreativeDetails.text}
                          </p>
                        </ScrollArea>
                        <div
                          className={
                            "p-2 border-t flex justify-between gap-1.5"
                          }
                        >
                          <div>
                            <p className={"text-sm"}>
                              {adCreativeDetails.headline}
                            </p>
                            <p
                              className={
                                "text-xs text-thememutedforeground font-light"
                              }
                            >
                              {adCreativeDetails.description}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={"gap-1.5 grid grid-cols-2"}>
                        <div className={"flex items-center gap-1.5"}>
                          <BookOpen size={"1rem"} />
                          <h5 className={"text-sm"}>Reading Score</h5>
                          <Tooltip open={openReadabilityTooltip}>
                            <TooltipTrigger
                              onClick={() => {
                                setOpenReadabilityTooltip(
                                  !openReadabilityTooltip,
                                );
                              }}
                              onMouseEnter={() => {
                                setOpenReadabilityTooltip(
                                  !openReadabilityTooltip,
                                );
                              }}
                              onMouseLeave={() => {
                                setOpenReadabilityTooltip(false);
                              }}
                            >
                              <InfoCircle
                                size={"1rem"}
                                className={"text-thememutedforeground"}
                              />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p className={"text-sm"}>
                                This copy reads at a{" "}
                                {getReadingLevel(readabilityScore)} Level
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        </div>
                        {readabilityScore && (
                          <div className={"flex items-center gap-1.5"}>
                            <Progress
                              value={readabilityScore}
                              className={`h-2 `}
                            />
                            <span className="w-[20%] text-sm font-light text-thememutedforeground">
                              {readabilityScore}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className={"gap-1.5 grid grid-cols-2"}>
                        <div className={"flex items-center gap-1.5"}>
                          <Box size={"1rem"} />
                          <h5 className={"text-sm"}>Persuasion Score</h5>
                          <Tooltip open={openPersuasionTooltip}>
                            <TooltipTrigger
                              onClick={() => {
                                setOpenPersuasionTooltip(
                                  !openPersuasionTooltip,
                                );
                              }}
                              onMouseEnter={() => {
                                setOpenPersuasionTooltip(
                                  !openPersuasionTooltip,
                                );
                              }}
                              onMouseLeave={() => {
                                setOpenPersuasionTooltip(false);
                              }}
                            >
                              <InfoCircle
                                size={"1rem"}
                                className={"text-thememutedforeground"}
                              />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p className={"text-sm"}>
                                {
                                  getPersuasionLevel(
                                    persuasionAnalysisResults.score,
                                  ).description
                                }
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        </div>
                        {persuasionAnalysisResults && (
                          <div className={"flex items-center gap-1.5"}>
                            <Progress
                              value={persuasionAnalysisResults.score}
                              className={`h-2 `}
                            />
                            <span className="w-[20%] text-sm font-light text-thememutedforeground">
                              {persuasionAnalysisResults.score}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <ScrollArea className="flex lg:hidden h-full">
            <div className="flex flex-col rounded lg:h-full w-full lg:max-w-[28rem]">
              <div className={"flex justify-end"}>
                <span onClick={onOpenChange} className={"p-1 cursor-pointer"}>
                  <X />
                </span>
              </div>

              <div className="flex flex-col  px-2 lg:px-8 py-3 pt-0 lg:py-12 lg:pt-4 gap-3 lg:gap-6">
                <div className={"flex gap-5 justify-between"}>
                  <div className={`flex gap-1 items-center`}>
                    {data.brandImage && data.brandId && (
                      <Link
                        to={`/feeds/brands/$brandID`}
                        params={{ brandID: data.brandId }}
                      >
                        <img
                          src={data.brandImage}
                          alt={data.brandName || "Brand name"}
                          className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain`}
                        ></img>
                      </Link>
                    )}
                    <div>
                      {data.brandName && data.brandId && (
                        <Link
                          to={`/feeds/brands/$brandID`}
                          params={{ brandID: data.brandId }}
                        >
                          <h4 className={`truncate font-[500] text-sm`}>
                            {data.brandName}
                          </h4>
                        </Link>
                      )}
                      <p className={"text-xs text-thememutedforeground"}>
                        Currently running{" "}
                        {numberOfActiveAdsForBrand?.numberOfActiveAds || 0} ads
                      </p>
                    </div>
                  </div>

                  <div className={"flex gap-2"}>
                    {!IsPublic && data && (
                      <ManuallySaveAdToBoard
                        open={saveOpen}
                        onOpenChange={setSaveOpen}
                        data={data}
                        variant={"outline"}
                      />
                    )}

                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          onClick={() => {
                            copyToClipboard(
                              `${window.location.origin}/share/inspiration/${data.id}`,
                            );
                            showToastNotification("success", {
                              message: "Copied!",
                            });
                          }}
                          variant={"outline"}
                          size={"icon"}
                        >
                          <LinkIcon />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Share a public link</p>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                </div>

                <div>
                  <p className={"font-semibold text-xl"}>
                    <span>{data.brandName}</span> Ad
                  </p>
                  <div
                    className={"flex gap-4 text-thememutedforeground text-sm"}
                  >
                    <div>
                      <span className={"flex items-center gap-2"}>
                        {data.isActiveSinceLastScrape ? (
                          <>
                            <span>
                              <TrendingUp className={"text-green-500"} />
                            </span>
                            <span>Active for {data.adRunningDays} Days</span>
                          </>
                        ) : (
                          <>
                            <span>
                              <TrendingDown className={"text-amber-500"} />
                            </span>
                            <span>Inactive for {data.inactiveTime} Days</span>
                          </>
                        )}
                      </span>
                    </div>
                    <div className={"flex items-center gap-1"}>
                      <span>
                        <Like className={"w-4.5"} />
                      </span>
                      <span>
                        {adCreativeDetails?.numberOfRequests || 0} Requests
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className={`grid grid-cols-${!data.videoUrl ? "2" : "1"} gap-3`}
                >
                  {!data.videoUrl && (
                    <Button
                      onClick={
                        !authUser
                          ? () => setAccessPromptDialogOpen(true)
                          : () => {
                              if (permissions === undefined) {
                                showToastNotification("warning", {
                                  description: REQUEST_TEMPLATE_DESCRIPTION,
                                  message: REQUEST_TEMPLATE_MESSAGE,
                                });
                              } else if (permissions.hasRequestLimitReached) {
                                showToastNotification("warning", {
                                  description: `You're only able to request ${permissions.requestLimit} templates per month on the Basic Plan. Restarts on ${permissions.limitNextUpdateAt}.`,
                                  message: "You’ve maxed out requests",
                                });
                              } else {
                                setAdRequested(true);
                                requestAdTemplate({
                                  adId: data.id,
                                }).then(() => {
                                  showToastNotification("success", {
                                    message: `${permissions.limitLeft > 0 ? permissions.limitLeft - 1 : 0} Template Requests left!`,
                                    description: `You have ${permissions.limitLeft > 0 ? permissions.limitLeft - 1 : 0} template requests left for the month.`,
                                  });
                                  utils.permissions.invalidate();
                                  refetch();
                                  setTimeout(() => {
                                    setAdRequested(false);
                                  }, 5000);
                                });
                              }
                            }
                      }
                      className={`${(permissions?.hasRequestLimitReached ?? true) ? "opacity-50" : ""}`}
                    >
                      {(permissions?.hasRequestLimitReached ?? true) ? (
                        <Lock className="w-5 h-5 mr-2" />
                      ) : (
                        <></>
                      )}

                      {adRequested ? "Requested!" : "Request Template"}
                    </Button>
                  )}
                  {adCreativeDetails?.landingPageUrl && (
                    <a
                      href={adCreativeDetails?.landingPageUrl}
                      target={"_blank"}
                      className={cn(buttonVariants({ variant: "secondary" }))}
                    >
                      View Landing Page
                    </a>
                  )}
                </div>
                {adCreativeDetails && (
                  <div className={"flex flex-col gap-4 text-sm"}>
                    <div className={"flex justify-between items-center gap-1"}>
                      <div className={"flex items-center gap-1"}>
                        {adCreativeDetails.rating > 4 && <span>🔥</span>}
                        <span className={"font-medium"}>
                          {{
                            5: "Excellent",
                            4: "Great",
                            3: "Good",
                            2: "Average",
                            1: "Low",
                          }[adCreativeDetails.rating] || ""}{" "}
                          Performance
                        </span>

                        <Tooltip open={openTooltip}>
                          <TooltipTrigger>
                            <span
                              className={"text-thememutedforeground"}
                              onClick={() => {
                                setOpenTooltip(!openTooltip);
                              }}
                              onMouseEnter={() => {
                                setOpenTooltip(!openTooltip);
                              }}
                              onMouseLeave={() => {
                                setOpenTooltip(false);
                              }}
                            >
                              <InfoCircle size={"1.2rem"} />
                            </span>
                          </TooltipTrigger>
                          <TooltipContent className="max-w-sm">
                            <p>
                              Performance Score is an estimate calculated using
                              benchmarks like Ad Longevity, Ad Spend, and
                              performance metrics
                            </p>
                          </TooltipContent>
                        </Tooltip>
                      </div>
                      {adCreativeDetails?.rating && (
                        <div className={"flex gap-1"}>
                          {[...Array(5)].map((_, index: number) => (
                            <div
                              key={index}
                              className={`rounded-full ${index < adCreativeDetails.rating ? "bg-themedestructive" : "bg-purple-100"} w-8 h-2`}
                            ></div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className={"flex justify-between items-center gap-1"}>
                      <div className={"flex items-center gap-1.5"}>
                        <span>
                          <CalendarUp size={"1.2rem"} />
                        </span>
                        <span className={"font-medium"}>Published</span>
                      </div>
                      <div>
                        <span className={"text-thememutedforeground"}>
                          {adCreativeDetails.publishedDate &&
                          !isNaN(
                            new Date(adCreativeDetails.publishedDate).getTime(),
                          )
                            ? new Date(
                                adCreativeDetails.publishedDate,
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })
                            : ""}
                        </span>
                      </div>
                    </div>
                    <div className={"flex justify-between items-center gap-1"}>
                      <div className={"flex items-center gap-1.5"}>
                        <span>
                          <Clock7 size={"1.2rem"} />
                        </span>
                        <span className={"font-medium"}>Run Time</span>
                      </div>
                      <div>
                        <span className={"text-thememutedforeground"}>
                          {data.isActiveSinceLastScrape ? (
                            <>{data.adRunningDays} Days and still running</>
                          ) : (
                            <>Inactive for {data.inactiveTime} Days</>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className={"pt-4 border-t space-y-4"}>
                      <h4 className={"font-semibold"}>Ad Copy</h4>
                      <div className={"border rounded"}>
                        <ScrollArea className={"p-2 h-[20svh] relative"}>
                          <div
                            className={
                              "flex justify-end absolute top-1 right-1"
                            }
                          >
                            <Button
                              onClick={() => {
                                copyToClipboard(copyText).then(() => {
                                  setCopiedText(true);
                                  showToastNotification("success", {
                                    message: "Copied!",
                                  });
                                  setTimeout(() => {
                                    setCopiedText(false);
                                  }, 2000);
                                });
                              }}
                              variant={"outline"}
                              size={"sm"}
                              className={"flex gap-1.5 items-center"}
                            >
                              <span className={"text-sm font-light"}>
                                {copiedText ? "Copied!" : "Copy"}
                              </span>
                              <Copy strokeWidth={1} size={"1.2rem"} />
                            </Button>
                          </div>
                          <p className={"text-thememutedforeground"}>
                            {adCreativeDetails.headline}
                          </p>
                          <p className={"text-thememutedforeground"}>
                            {adCreativeDetails.description}
                          </p>
                          <p className={"text-thememutedforeground"}>
                            {adCreativeDetails.text}
                          </p>
                        </ScrollArea>
                        <div
                          className={
                            "p-2 border-t flex justify-between gap-1.5"
                          }
                        >
                          <div>
                            <p className={"text-sm"}>
                              {adCreativeDetails.headline}
                            </p>
                            <p
                              className={
                                "text-xs text-thememutedforeground font-light"
                              }
                            >
                              {adCreativeDetails.description}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={"gap-1.5 grid grid-cols-2"}>
                        <div className={"flex items-center gap-1.5"}>
                          <BookOpen size={"1rem"} />
                          <h5 className={"text-sm"}>Reading Score</h5>
                          <Tooltip open={openReadabilityTooltip}>
                            <TooltipTrigger
                              onClick={() => {
                                setOpenReadabilityTooltip(
                                  !openReadabilityTooltip,
                                );
                              }}
                              onMouseEnter={() => {
                                setOpenReadabilityTooltip(
                                  !openReadabilityTooltip,
                                );
                              }}
                              onMouseLeave={() => {
                                setOpenReadabilityTooltip(false);
                              }}
                            >
                              <InfoCircle
                                size={"1rem"}
                                className={"text-thememutedforeground"}
                              />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p className={"text-sm"}>
                                This copy reads at a{" "}
                                {getReadingLevel(readabilityScore)} Level
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        </div>
                        {readabilityScore && (
                          <div className={"flex items-center gap-1.5"}>
                            <Progress
                              value={readabilityScore}
                              className={`h-2 `}
                            />
                            <span className="w-[20%] text-sm font-light text-thememutedforeground">
                              {readabilityScore}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className={"gap-1.5 grid grid-cols-2"}>
                        <div className={"flex items-center gap-1.5"}>
                          <Box size={"1rem"} />
                          <h5 className={"text-sm"}>Persuasion Score</h5>
                          <Tooltip open={openPersuasionTooltip}>
                            <TooltipTrigger
                              onClick={() => {
                                setOpenPersuasionTooltip(
                                  !openPersuasionTooltip,
                                );
                              }}
                              onMouseEnter={() => {
                                setOpenPersuasionTooltip(
                                  !openPersuasionTooltip,
                                );
                              }}
                              onMouseLeave={() => {
                                setOpenPersuasionTooltip(false);
                              }}
                            >
                              <InfoCircle
                                size={"1rem"}
                                className={"text-thememutedforeground"}
                              />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p className={"text-sm"}>
                                {
                                  getPersuasionLevel(
                                    persuasionAnalysisResults.score,
                                  ).description
                                }
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        </div>
                        {persuasionAnalysisResults && (
                          <div className={"flex items-center gap-1.5"}>
                            <Progress
                              value={persuasionAnalysisResults.score}
                              className={`h-2 `}
                            />
                            <span className="w-[20%] text-sm font-light text-thememutedforeground">
                              {persuasionAnalysisResults.score}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <ScrollArea
              className={"bg-themeaccent rounded h-full w-full min-h-[65vh]"}
            >
              <InspirationDetailsMediaSection
                adCreativeDetails={adCreativeDetails}
                data={data}
              />{" "}
            </ScrollArea>
          </ScrollArea>
        </DrawerContent>
      )}
    </Drawer>
  );
}

import { createFileRoute, Link } from "@tanstack/react-router";
import { Stack, Text } from "@/components/custom-components";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { Link as LinkIcon, Pencil, Trash } from "@mynaui/icons-react";
import { useState } from "react";
import { Ad } from "@/components/templates/DiscoverGridView";
import { Loader } from "@/components/custom-components/Loader";
import { ArrowLeftIcon } from "lucide-react";
import { Button } from "@/components/shadcn/button";
import showToastNotification from "@/hooks/useShowToast";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/shadcn/select";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { AdCard, DiscoverAdCard } from "@/components/templates/AdCard";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs";
import useCardLayoutWithImpressions from "@/hooks/useCardLayoutWithImpressions.tsx";
import { DeleteBoardDialog } from "@/components/DeleteBoard.tsx";
import { BoardDialog } from "@/components/BoardDialog.tsx";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/boards/$boardID/ads")({
  component: BoardAds,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function BoardAds() {
  const { boardID } = Route.useParams();

  const [editBoardDialogOpen, setEditBoardDialogOpen] =
    useState<boolean>(false);

  const [selectValue, setSelectValue] = useState<
    "All Saved" | "Only Templates"
  >("All Saved");

  const [deleteBoardDialogOpen, setDeleteBoardDialogOpen] =
    useState<boolean>(false);

  const [, copyBoardAdToClipboard] = useCopyToClipboard();

  const {
    data: boardDetails,
    isLoading,
    error,
  } = trpc.getBoardDetails.useQuery(
    {
      boardId: boardID,
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  const { squareRef, desiredCardWidth, columns, gutterWidth, addPostToBatch } =
    useCardLayoutWithImpressions();

  return (
    <>
      {editBoardDialogOpen && boardDetails && (
        <BoardDialog
          data={{
            name: boardDetails.name,
            id: boardDetails.id,
            description: boardDetails.description || "",
          }}
          open={editBoardDialogOpen}
          onOpenChange={() => setEditBoardDialogOpen(false)}
        />
      )}
      {deleteBoardDialogOpen && boardDetails && (
        <DeleteBoardDialog
          data={{
            name: boardDetails.name,
            id: boardDetails.id,
            description: boardDetails.description || "",
          }}
          open={deleteBoardDialogOpen}
          onOpenChange={() => setDeleteBoardDialogOpen(false)}
        />
      )}
      <Stack className="gap-3 lg:gap-8">
        <Stack className="gap-3 lg:gap-8">
          <Stack className="gap-3 lg:gap-2">
            <Link to={"/feeds/boards"} className="flex gap-1.5 w-10">
              <Button variant={"link"} className="p-0" size={"sm"}>
                <ArrowLeftIcon size={"1rem"} />
                All My Collections
              </Button>
            </Link>
            {boardDetails && (
              <div className={"flex justify-between gap-5 items-center"}>
                <Text weight="semibold" size={"xxl"}>
                  {boardDetails?.name}
                </Text>

                <div className="flex gap-1.5 h-8">
                  <Button
                    onClick={() => {
                      copyBoardAdToClipboard(
                        `${window.location.origin}/feeds/boards/${boardDetails?.id}/ads`,
                      );
                      showToastNotification("success", { message: "Copied!" });
                    }}
                    className="hidden lg:flex gap-1.5"
                    variant={"outline"}
                    size={"sm"}
                  >
                    <LinkIcon className="w-5 h-5" />
                    Share
                  </Button>
                  <Button
                    onClick={() => {
                      copyBoardAdToClipboard(
                        `${window.location.origin}/feeds/boards/${boardDetails?.id}/ads`,
                      );
                      showToastNotification("success", { message: "Copied!" });
                    }}
                    className="lg:hidden flex gap-1.5"
                    variant={"outline"}
                    size={"iconMid"}
                  >
                    <LinkIcon className="w-5 h-5" />
                  </Button>
                  <Button
                    onClick={() => setEditBoardDialogOpen(true)}
                    className="gap-1.5"
                    variant={"outline"}
                    size={"iconMid"}
                  >
                    <Pencil className="w-5 h-5" />
                  </Button>
                  <Button
                    onClick={() => setDeleteBoardDialogOpen(true)}
                    className="gap-1.5"
                    variant={"outline"}
                    size={"iconMid"}
                  >
                    <Trash className="w-5 h-5" />
                  </Button>
                </div>
              </div>
            )}
            {boardDetails && (
              <div className={`flex gap-1 items-center justify-between`}>
                <div className={`flex gap-1 items-center`}>
                  <Text
                    className="text-thememutedforeground w-fit"
                    size={"base"}
                    weight={"normal"}
                  >
                    {boardDetails?.description}
                  </Text>
                </div>
                <Text
                  className="text-thememutedforeground w-fit text-nowrap hidden lg:flex"
                  size={"base"}
                  weight={"normal"}
                >
                  {selectValue == "All Saved"
                    ? (boardDetails?.ads.length ?? 0) +
                      (boardDetails?.adTemplates ?? []).length
                    : (boardDetails?.adTemplates ?? []).length}
                  {(selectValue == "All Saved"
                    ? (boardDetails?.ads.length ?? 0) +
                      (boardDetails?.adTemplates ?? []).length
                    : (boardDetails?.adTemplates ?? []).length) == 1
                    ? " Item"
                    : " Items"}
                </Text>
              </div>
            )}
          </Stack>

          <div className="flex items-center gap-6">
            <FeatureTabs
              showPro={false}
              tabItems={[
                {
                  name: "Ads",
                  link: `/feeds/boards/${boardID}/ads`,
                },
                {
                  name: "Landers",
                  link: `/feeds/boards/${boardID}/ad-landers`,
                },
                {
                  name: "Emails",
                  link: `/feeds/boards/${boardID}/emails`,
                },
              ]}
            />
            <Select
              value={selectValue}
              onValueChange={(value: "All Saved" | "Only Templates") =>
                setSelectValue(value)
              }
            >
              <SelectTrigger className="w-33 h-8 max-h-8">
                <SelectValue placeholder="Ad Type" className="rounded-md" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {["All Saved", "Only Templates"].map((item, idx) => (
                    <SelectItem key={idx} value={item} className="py-1.5">
                      {item}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </Stack>

        {isLoading ? (
          <div
            className={
              "flex justify-center items-center w-full h-screen m-auto"
            }
          >
            <Loader />
          </div>
        ) : error ? (
          <ErrorDisplay />
        ) : (
          <div className={""}>
            {boardDetails && (
              <>
                {boardDetails?.ads.length === 0 &&
                boardDetails?.adTemplates.length == 0 ? (
                  <div className={"lg:h-96 flex justify-center items-center"}>
                    <p>No ad added to this board</p>
                  </div>
                ) : (
                  ((boardDetails?.adTemplates ?? []).length > 0 ||
                    (boardDetails?.ads ?? []).length > 0) && (
                    <div ref={squareRef} className={"relative w-full pb-10"}>
                      <div>
                        <ResponsiveMasonry
                          columnsCountBreakPoints={
                            columns ? { 0: columns } : {}
                          } // Columns is determined by the width of the container
                        >
                          <Masonry gutter={gutterWidth / 16 + "rem"}>
                            {(boardDetails?.adTemplates ?? []).map((ad) => {
                              return (
                                <AdCard
                                  key={ad.atID}
                                  showTemplateButton
                                  adData={{
                                    Ad: ad,
                                    IsLocked: false,
                                    IsPublic: false,
                                    brandName: undefined,
                                    shouldInvalidateCache: false,
                                    DesiredWidth: desiredCardWidth,
                                  }}
                                />
                              );
                            })}
                            {boardDetails?.ads.map((ad) =>
                              selectValue == "All Saved" && !ad.adTemplate ? (
                                <DiscoverAdCard
                                  key={ad.id}
                                  DesiredWidth={desiredCardWidth}
                                  adData={ad as Ad}
                                  IsPublic={false}
                                  IsLocked={false}
                                  onView={addPostToBatch}
                                />
                              ) : ad.adTemplate ? (
                                <AdCard
                                  key={ad.adTemplate?.atID}
                                  showTemplateButton
                                  adData={{
                                    Ad: ad.adTemplate,
                                    IsLocked: false,
                                    IsPublic: false,
                                    brandName: undefined,
                                    shouldInvalidateCache: false,
                                    DesiredWidth: desiredCardWidth,
                                  }}
                                />
                              ) : (
                                <></>
                              ),
                            )}
                          </Masonry>
                        </ResponsiveMasonry>
                      </div>
                    </div>
                  )
                )}
              </>
            )}
          </div>
        )}
      </Stack>
    </>
  );
}

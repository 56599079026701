import { createFileRoute } from "@tanstack/react-router";
import { Tags } from "../../../../src/shared/airtable";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { Loader } from "@/components/custom-components/Loader";
import { DiscoverGridView } from "@/components/templates/DiscoverGridView";
import { Stack, Text } from "@/components/custom-components";

type SearchParams = {
  Tags?: Tags;
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/inspiration/$adID")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const tag = search?.Tags as Tags | undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      Tags: tag,
      sideBarOpen,
    };
  },
});

function All() {
  const { adID } = Route.useParams();

  const {
    data: adInspiration,
    isError,
    isLoading,
    isRefetching,
  } = trpc.getAd.useQuery(
    {
      adId: parseInt(adID),
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  if (isError) {
    return (
      <div className={"px-10"}>
        {}
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (!adInspiration || isLoading || isRefetching) {
    return (
      <div
        className={"flex justify-center items-center w-full h-[70vh] m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div className={"relative h-[97vh]"}>
      <Stack className="!mb-6 gap-3">
        <div
          className={"flex justify-between lg:justify-start gap-5 items-center"}
        >
          <Text weight="semibold" size={"xxl"}>
            Ad Inspiration
          </Text>
        </div>
        <Text
          className="text-thememutedforeground w-fit"
          size={"base"}
          weight={"normal"}
        >
          Somebody shared an Ad Inspiration with you!
        </Text>
      </Stack>
      <DiscoverGridView data={[adInspiration]} />
    </div>
  );
}

import { createFileRoute } from "@tanstack/react-router";

import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import {
  BrandDataProps,
  BrandsGridView,
} from "@/components/templates/BrandsGridView";
import { Loader } from "@/components/custom-components/Loader";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/shadcn/form.tsx";
import { Search } from "@mynaui/icons-react";
import { Input } from "@/components/shadcn/input.tsx";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Stack, Text } from "@/components/custom-components";
import { Button } from "@/components/shadcn/button.tsx";
import { Loading } from "@/components/loading.tsx";
import { CheckCircle2 } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/shadcn/dialog.tsx";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/brands/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const [requestBrandOpen, setRequestBrandOpen] = useState<boolean>(false);

  const {
    data: brands,
    isLoading,
    error,
  } = trpc.getBrands.useQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (brands) setFilteredBrands(brands);
  }, [brands]);

  const [filteredBrands, setFilteredBrands] = useState<BrandDataProps[]>([]);

  if (error) {
    return <ErrorDisplay />;
  }

  if (isLoading) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <Stack className="gap-3 lg:gap-6">
      {requestBrandOpen && (
        <RequestBrand
          open={requestBrandOpen}
          onOpenChange={() => setRequestBrandOpen(false)}
        />
      )}
      
        <Stack className="gap-3 lg:gap-6 lg:sticky lg:top-0 lg:z-10 lg:bg-white lg:py-2 flex justify-between items-center w-full">
          <div
            className={
              "flex justify-between lg:justify-start gap-5 items-center w-full"
            }
          >
            <Text weight="semibold" size={"xxl"}>
              Brands {(brands ?? []).length > 0 ? `(${brands?.length})`:""}
            </Text>
            <div className={"lg:flex hidden gap-3"}>
              <div>
                <Button onClick={() => setRequestBrandOpen(true)} size={"sm"}>
                  Request Brand
                </Button>
              </div>
              {brands && (
                <BrandSearchForm
                  data={brands}
                  setSearchResults={setFilteredBrands}
                />
              )}
            </div>
          </div>
          <div className={"flex lg:hidden w-full gap-3"}>
            {brands && (
              <BrandSearchForm
                data={brands}
                setSearchResults={setFilteredBrands}
              />
            )}
            <div>
              <Button onClick={() => setRequestBrandOpen(true)} size={"sm"}>
                Request Brand
              </Button>
            </div>
          </div>
        </Stack>

      {brands && filteredBrands && <BrandsGridView data={filteredBrands} />}
    </Stack>
  );
}

export default function BrandSearchForm({
  data,
  setSearchResults,
}: {
  data: BrandDataProps[];
  setSearchResults: (data: BrandDataProps[]) => void;
}) {
  const searchFormSchema = z.object({
    brand: z.string({ required_error: "Brand is required" }),
  });

  type SearchFormValues = z.infer<typeof searchFormSchema>;

  const defaultValues: Partial<SearchFormValues> = {};

  const form = useForm<SearchFormValues>({
    resolver: zodResolver(searchFormSchema),
    defaultValues,
  });

  const handleSearch = (e: FormEvent) => e.preventDefault();

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) return setSearchResults(data);
    const results = data.filter((item) =>
      item.brandName?.toLowerCase().includes(e.target?.value)
    );
    setSearchResults(results);
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSearch}>
        <FormField
          name="search"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="relative">
                  {/* Search Icon positioned on the left */}
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <Search
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                  <Input
                    type={"text"}
                    {...field}
                    onChange={handleSearchChange}
                    placeholder="Brand Name"
                    className="pl-10 lg:w-80 w-[calc(100vw-24px)]" // Padding left to make space for the icon
                  />
                </div>
              </FormControl>
            </FormItem>
          )}
        ></FormField>
      </form>
    </Form>
  );
}

function RequestBrand({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: () => void;
}) {
  const [message, setMessage] = useState<string | null>(null);

  const { mutate: requestBrand, isPending: createIsPending } =
    trpc.requestABrand.useMutation({
      onSuccess: async () => {
        setMessage("Request submitted successfully");
      },
    });

  const formSchema = z.object({
    brandName: z.string().min(1, "Brand name is required"),
    brandDomain: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      brandName: "",
      brandDomain: "",
    },
  });

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      requestBrand(values);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={open || createIsPending} onOpenChange={onOpenChange}>
      <DialogContent className={"gap-6"}>
        <DialogHeader>
          <DialogTitle>
            <Text weight={"semibold"} size={"lg"} className="leading-7">
              {"Request Brand"}
            </Text>
          </DialogTitle>
        </DialogHeader>
        {message ? (
          <div
            className={
              "flex flex-col items-center text-center gap-2 w-full h-full m-auto"
            }
          >
            <CheckCircle2 size={"3rem"} strokeWidth={"1.2"} color={"green"} />
            <p>{message}</p>
          </div>
        ) : createIsPending ? (
          <div className={"flex flex-col gap-3 w-full h-full m-auto"}>
            <Loading />
          </div>
        ) : (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className={""}>
              <div className={"flex flex-col gap-6"}>
                <FormField
                  control={form.control}
                  name="brandName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-medium leading-5">
                        Brand Name
                      </FormLabel>
                      <FormControl>
                        <Input
                          className="shadow-none"
                          placeholder={"Name"}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="brandDomain"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Brand Domain</FormLabel>
                      <FormControl>
                        <Input
                          type={"text"}
                          className="shadow-none"
                          placeholder={"www.website.com"}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className={"flex justify-end gap-2"}>
                  <Button
                    className={"w-15 px-3 h-8 text-sm"}
                    variant={"outline"}
                    type={"button"}
                    onClick={onOpenChange}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={"submit"}
                    className={"w-15 px-3 h-8 text-sm"}
                    variant={"default"}
                  >
                    {createIsPending ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </div>
            </form>
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
}

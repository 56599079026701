import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { ErrorDisplay } from "@/components/error.tsx";
import { trpc } from "@/utils/trpc.ts";
import { Loader } from "@/components/custom-components/Loader";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  LandingFeedAdCard,
  LandingFeedProps,
} from "@/components/templates/LandingAdCard";
import { toast } from "sonner";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import { Link as LinkIcon } from "@mynaui/icons-react";
import { Button } from "@/components/shadcn/button.tsx";
import { Stack, Text } from "@/components/custom-components";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/shadcn/breadcrumb";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs";
import { LandingAdSearchParams } from "@/hooks/useFilterFeed.tsx";
import { OrderFilterSelect } from "@/components/ad-inspiration/OrderFilterSelect.tsx";
import { z } from "zod";
import { landerFeedOrderFilter } from "../../../../src/shared/airtable";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { featureFlagKeys } from "@/utils/data/featureFlags";

export const Route = createFileRoute("/feeds/brands/$brandID/landing-pages")({
  component: All,
  validateSearch: (search: Record<string, unknown>): LandingAdSearchParams => {
    const industry = search?.industry as string | undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof landerFeedOrderFilter>
      | undefined;

    return {
      industry,
      sideBarOpen,
      orderFilter,
    };
  },
});

function All() {
  const { brandID } = Route.useParams();
  const navigate = useNavigate();
  const BRAND_TABS = [
    {
      name: "Ads",
      link: `/feeds/brands/${brandID}`,
    },
    {
      name: "Emails",
      link: `/feeds/brands/${brandID}/emails`,
    },
    {
      name: "Landers",
      link: `/feeds/brands/${brandID}/landing-pages`,
    },
  ];

  const getBrandTabs = ({
    showEmailFeeds = false,
  }: {
    showEmailFeeds?: boolean;
  }) => {
    return showEmailFeeds
      ? BRAND_TABS
      : BRAND_TABS.filter((tab) => tab.name !== "Emails");
  };

  const { orderFilter } = Route.useSearch();

  const [allData, setAllData] = useState<LandingFeedProps[] | undefined>(
    undefined
  );
  const [cursor, setCursor] = useState(1);

  // update cursor whenever selectedFilters change
  useEffect(() => {
    setCursor(1); // Reset cursor to 1 whenever filters change
  }, [orderFilter]);

  const [, copyToClipboard] = useCopyToClipboard();

  const {
    data: brandLanders,
    fetchNextPage,
    isLoading,
    isError,
    isRefetching,
  } = trpc.getBrandLanders.useInfiniteQuery(
    {
      brandId: brandID,
      limit: 20,
      filters: {
        sort: orderFilter,
      },
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      initialCursor: cursor,
    }
  );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (!inView || !brandLanders || !allData || !allData.length) return;
    if (
      brandLanders.pages[brandLanders.pages.length - 1].results.length === 0
    ) {
      return;
    }
    fetchNextPage();
  }, [inView, brandLanders, allData, fetchNextPage]);

  useEffect(() => {
    if (!brandLanders) return;

    setAllData(() => undefined);
    const records = [] as LandingFeedProps[];
    for (const page of brandLanders.pages) {
      records.push(...page.results);
    }
    setAllData(() => records);
  }, [brandLanders]);

  const flagEnabled = useFeatureFlagEnabled(featureFlagKeys.brandEmails);
  const [isResolved, setIsResolved] = useState(false);

  useEffect(() => {
    if (flagEnabled !== undefined) {
      setIsResolved(true); // Mark the flag as resolved once its value is known
    }
  }, [flagEnabled]);

  useEffect(() => {
    if (isResolved && flagEnabled === false) {
      navigate({ to: `/feeds/brands/$brandID`, params: { brandID } });
    }
  }, [isResolved, flagEnabled, navigate]);

  if (!isResolved) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="px-10">
        <ErrorDisplay />
      </div>
    );
  }

  return (
    <Stack className="gap-3 lg:gap-6">
      <Stack className="gap-3 lg:gap-8">
        <Stack className="gap-3 lg:gap-2">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/feeds/brands">Brands</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>
                  {(brandLanders?.pages ?? []).length > 0
                    ? brandLanders?.pages[0].brandName
                    : ""}
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <div
            className={`flex ${(brandLanders?.pages ?? []).length > 0 ? "justify-between" : "justify-end"} gap-5 items-center`}
          >
            {(brandLanders?.pages ?? []).length > 0 && (
              <div className="flex gap-2 items-center">
                <img
                  className={"rounded-full min-w-12 w-12 h-12 bg-themeaccent"}
                  src={
                    brandLanders?.pages[0].brandImage
                      ? brandLanders.pages[0].brandImage
                      : "/bg_gradient.jpg"
                  }
                  alt={brandLanders?.pages[0].brandName}
                />
                <Text weight="semibold" size={"xxl"}>
                  {brandLanders?.pages[0].brandName}
                </Text>
              </div>
            )}
            <Button
              onClick={() => {
                copyToClipboard(
                  `${window.location.origin}/feeds/brands/${brandID}/landing-pages`
                );
                toast.success("Copied!");
              }}
              className={"flex"}
              variant={"outline"}
              size={"iconSm"}
            >
              <LinkIcon />
            </Button>
          </div>
        </Stack>
        <div
          className={"flex justify-between lg:justify-start gap-5 items-center"}
        >
          <FeatureTabs
            showPro={false}
            defaultOrderValue={"Random"}
            tabItems={getBrandTabs({ showEmailFeeds: flagEnabled })}
          />
          <div className={"flex lg:hidden"}>
            <OrderFilterSelect
              defaultFilter={orderFilter || "Random"}
              options={["Random", "Newest", "Popular", "Most Requested"]}
            />
          </div>
        </div>
      </Stack>
      <div
        className={`lg:sticky lg:top-0 lg:bg-white lg:py-2 flex justify-end items-center gap-1.5`}
      >
        <div className={"hidden lg:flex"}>
          <OrderFilterSelect
            defaultFilter={orderFilter || "Random"}
            options={["Random", "Newest", "Popular", "Most Requested"]}
          />
        </div>
      </div>
      {isLoading || isRefetching ? (
        <div className="flex justify-center items-center w-full h-screen">
          <Loader />
        </div>
      ) : (
        <div>
          {brandLanders && allData && allData.length === 0 ? (
            <div className="flex flex-col justify-center items-center">
              <p className="text-center w-4/5 lg:w-1/2 mb-5">
                There are currently no landing page inspiration for this brand.
              </p>
              <img src="/giphy.webp" width="480" height="270" alt="" />
            </div>
          ) : (
            <div
              className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"}
            >
              {brandLanders &&
                allData &&
                allData.length > 0 &&
                allData.map((card) => (
                  <LandingFeedAdCard key={card.landerId} adData={card} />
                ))}
              <div className={"relative"}>
                <div
                  className={
                    "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
                  }
                  ref={scrollRef}
                ></div>
              </div>{" "}
              <div className={"relative"}>
                <div
                  className={
                    "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
                  }
                  ref={scrollRef}
                ></div>
              </div>
            </div>
          )}
        </div>
      )}
    </Stack>
  );
}

export default All;

import { TabsList, Tabs, TabsTrigger } from "@/components/shadcn/tabs";
import { Link } from "@tanstack/react-router";
import { PRO_PLAN, STANDARD_PLAN } from "@/utils/data/plans";
import { Button } from "@/components/shadcn/button";
import { Text } from "@/components/custom-components";
import { LockTagSmall } from "../LockTag";
import { z } from "zod";
import {
  landerFeedOrderFilter,
  orderFilter as OrderFilterTypes,
} from "../../../../../../src/shared/airtable";
import { trpc } from "@/utils/trpc";
import { useState } from "react";
import FreeTrialEnding from "@/components/FreeTrialEnding";

export const FeatureTabs = ({
  tabItems,
  useFirst2PathnamesOnly,
  showPro,
  defaultOrderValue = "Random",
}: {
  tabItems: Array<{ name: string; link: string; isPremiumFeature?: boolean }>;
  useFirst2PathnamesOnly?: boolean;
  showPro?: boolean;
  defaultOrderValue?:
    | z.infer<typeof landerFeedOrderFilter>
    | z.infer<typeof OrderFilterTypes>;
}) => {
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  const showProButton =
    permissionData?.usersPlans &&
    !(
      permissionData?.usersPlans.includes(PRO_PLAN.plan_id) ||
      permissionData?.usersPlans.includes(PRO_PLAN.no_trial_plan_id)
    ) &&
    (showPro ?? true);

  return (
    <>
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <div className={`flex justify-between items-center`}>
        <div className={"flex flex-1"}></div>
        <Tabs
          className="w-fit"
          value={
            useFirst2PathnamesOnly
              ? location.pathname
                  .split("/")
                  .filter(Boolean)
                  .slice(0, 2)
                  .join("/")
              : location.pathname
          }
        >
          <TabsList className="rounded-full">
            {tabItems.map((item) => (
              <TabsTrigger
                key={item.name}
                value={
                  useFirst2PathnamesOnly
                    ? item.link.split("/").filter(Boolean).slice(0, 2).join("/")
                    : item.link
                }
                className="rounded-full"
              >
                <Link
                  onClick={(e) => {
                    // If a user navigates before loading is complete, choose to just navigate
                    // This optimizes for users who ARE allowed access and doesn't force them to
                    // wait until we have the data back from the server if they have access

                    if (
                      item.isPremiumFeature &&
                      !isLoadingPermission &&
                      !permissionData?.userCanAccessEverything
                    ) {
                      setUpgradeDialogOpen(true);
                      e.preventDefault();
                    }
                  }}
                  to={item.link}
                  search={(d) => ({
                    ...d,
                    cacheBuster: Math.random(),
                    orderFilter: defaultOrderValue,
                    sideBarOpen: false,
                  })}
                  className="flex items-center gap-1.5"
                  activeOptions={{
                    exact: true,
                    includeSearch: false,
                  }}
                  key={item.name}
                >
                  {item.name}
                  {item.isPremiumFeature &&
                    !isLoadingPermission &&
                    !permissionData?.userCanAccessEverything && (
                      <div className="opacity-50">{<LockTagSmall />}</div>
                    )}
                </Link>
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
        <div className={"flex-1"}>
          {showProButton && (
            <div className="flex gap-3 items-center justify-end w-full">
              <Text className="w-fit">
                {permissionData?.usersPlans.includes(STANDARD_PLAN.plan_id)
                  ? "Go Pro. Supercharge your creativity."
                  : ""}
              </Text>
              <Button
                onClick={() => setUpgradeDialogOpen(true)}
                variant={"destructivePurple"}
                size={"sm"}
              >
                Get Pro
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

import { Properties } from "posthog-js";
import { usePostHog } from "posthog-js/react";

export const PostHogEvents = {
  USER_SHUFFLED_AD_INSPIRATION: "user shuffled ad inspiration",
  USER_SHUFFLED_EMAIL_INSPIRATION: "user shuffled email inspiration",
  USER_SHUFFLED_LANDERS_INSPIRATION: "user shuffled landers inspiration",
  USER_AD_TEMPLATE_CANVA_COPIED: "user ad template canva copied",
  USER_AD_TEMPLATE_FIGMA_COPIED: "user ad template figma copied",
  USER_EMAIL_TEMPLATE_FIGMA_COPIED: "user email template figma copied",
  USER_LANDING_PAGE_TEMPLATE_FIGMA_COPIED:
    "user landing page template figma copied",
} as const;

export type EventName = keyof typeof PostHogEvents;

export interface TemplateCopiedEventProperties extends Properties {
  templateId: string
}

/**
 * Custom hook to capture posthog events 
 */
export function useCapturePostHogEvent() {
  const posthog = usePostHog(); 

  function capture(event: EventName, properties?: TemplateCopiedEventProperties) {
    return posthog?.capture(PostHogEvents[event], properties);
  }

  return {
    capture
  }
}
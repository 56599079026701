import { Link } from "@tanstack/react-router";

export const SidebarButton = (props: { sideBarOpen?: boolean }) => {
  return (
    <Link
      search={(old) => ({
        ...old,
        sideBarOpen: !props.sideBarOpen,
      })}
      params={(old) => ({
        ...old,
      })}
      className={
        "flex lg:hidden items-center justify-center w-6 h-6 rounded-full"
      }
    >
      {props.sideBarOpen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-x"
        >
          <path d="M18 6 6 18" />
          <path d="m6 6 12 12" />
        </svg>
      ) : (
        <svg
          className={"fill-slate-700"}
          xmlns="http://www.w3.org/2000/svg"
          height="36"
          viewBox="0 -960 960 960"
          width="36"
        >
          <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
        </svg>
      )}
    </Link>
  );
};

import { createFileRoute, Link } from "@tanstack/react-router";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { Loader } from "@/components/custom-components/Loader";
import { Stack, Text } from "@/components/custom-components";
import { useState } from "react";
import { Button } from "@/components/shadcn/button";
import { Plus } from "@mynaui/icons-react";
import CollectionCard from "@/components/custom-components/CollectionCard";
import { BoardDialog } from "@/components/BoardDialog.tsx";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/boards/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const [createBoardDialogOpen, setCreateBoardDialogOpen] =
    useState<boolean>(false);
  const {
    data: myCollections,
    isLoading,
    error,
  } = trpc.getBoards.useQuery(
    { postType: "record" },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  if (error) {
    return <ErrorDisplay />;
  }

  if (isLoading) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      {createBoardDialogOpen && (
        <BoardDialog
          open={createBoardDialogOpen}
          onOpenChange={() => setCreateBoardDialogOpen(false)}
        />
      )}
      <Stack className="gap-3 lg:gap-6">
        <div
          className={"flex justify-between lg:justify-start gap-5 items-center"}
        >
          <Text weight="semibold" size={"xxl"}>
            Your Collections
          </Text>
          <Button
            onClick={() => setCreateBoardDialogOpen(true)}
            size={"sm"}
            variant={"default"}
            className="hidden lg:flex"
          >
            New Collection <Plus className="w-5 h-5" />
          </Button>
        </div>

        {myCollections && (
          <BoardCollectionGridView
            boards={myCollections}
            createBoardDialogOpen={() => setCreateBoardDialogOpen(true)}
          />
        )}
      </Stack>
    </>
  );
}

type BoardCollectionDataProps = {
  boards: BoardDataProps[];
  createBoardDialogOpen: () => void;
};

type BoardDataProps = {
  name: string;
  id: string;
  description: string | null;
  isAdInBoard?: boolean;
};

const BoardCollectionGridView = (props: BoardCollectionDataProps) => {
  return (
    <div className={"grid lg:grid-cols-2 gap-5 px-0"}>
      {...[
        ...props.boards.map((item) => {
          return <BoardCollectionItem data={item} key={item.id} />;
        }),
        ...(props.boards.length > 3
          ? []
          : [
              <div className={"overflow-y-hidden cursor-pointer"}>
                <Stack
                  className={
                    "rounded-lg h-96 border-dashed border-2 border-themeborder items-center justify-center gap-2"
                  }
                  onClick={() => props.createBoardDialogOpen()}
                >
                  <Button
                    variant={"default"}
                    size="icon"
                    className="rounded-full bg-thememutedforeground"
                  >
                    <Plus></Plus>
                  </Button>
                  <Text
                    className="text-thememutedforeground text-center"
                    size="xxl"
                    weight="normal"
                  >
                    Create New Collection
                  </Text>
                </Stack>
              </div>,
            ]),
      ]}
    </div>
  );
};

const BoardCollectionItem = ({ data }: { data: BoardDataProps }) => {
  const { data: boardDetails } = trpc.getBoardDetails.useQuery({
    boardId: data.id,
  });

  return (
    <Link to={`/feeds/boards/$boardID/ads`} params={{ boardID: data.id }}>
      <Stack className="gap-3">
        <CollectionCard
          imageUrls={[
            ...(boardDetails?.ads?.map((ad) => ad.imageUrl) || []),
            ...(boardDetails?.adTemplates?.map(
              (adTemplate) => adTemplate["Example Image"][0].url,
            ) || []),
            ...(boardDetails?.landers?.map(
              (lander) => lander.desktopScreenshot || lander.mobileScreenshot,
            ) || []),
          ]}
          videoUrls={boardDetails?.ads.map((ad) => ad.videoUrl) ?? []}
        />
        <div className="flex justify-between items-center">
          <Stack className="gap-1 w-full">
            <h4
              className={`truncate font-semibold text-sm leading-5 text-themeforeground`}
            >
              {boardDetails?.name}
            </h4>
          </Stack>
          <Text
            className="text-thememutedforeground w-fit text-nowrap"
            size={"xs"}
            weight={"normal"}
          >
            {
              [
                ...(boardDetails?.ads || []),
                ...(boardDetails?.adTemplates || []),
                ...(boardDetails?.landers || []),
              ].length
            }{" "}
            {[
              ...(boardDetails?.ads || []),
              ...(boardDetails?.adTemplates || []),
              ...(boardDetails?.landers || []),
            ].length > 1
              ? " Items"
              : "Item"}
          </Text>
        </div>
      </Stack>
    </Link>
  );
};

import { createFileRoute, Link } from "@tanstack/react-router";

import { Stack, Text } from "@/components/custom-components";
import CollectionCard from "@/components/custom-components/CollectionCard/index.tsx";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
import { Loader } from "@/components/custom-components/Loader";
import { LockTagSmall } from "@/components/custom-components/LockTag/index.tsx";
import { ErrorDisplay } from "@/components/error.tsx";
import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";
import { Badge } from "@/components/shadcn/badge.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import { trpc } from "@/utils/trpc.ts";
import { Plus, UserWaves } from "@mynaui/icons-react";
import { useEffect, useState } from "react";
import { Expert } from "../../../../src/shared/airtable";
import { BoardDialog } from "@/components/BoardDialog.tsx";

type BoardCollectionDataProps = {
  collections: ExpertProductType[];
};

type SearchParams = {
  sideBarOpen?: boolean;
};

export type ExpertProductType = {
  title: string;
  description: string;
  url: string;
  ctaButton: string;
  priceID: string;
  planID: string[];
  imageLink: string;
};

export const ExpertProducts: ExpertProductType[] = [
  {
    title: "Fraser Cottrell",
    url: "/feeds/collections/experts/Fraser%20Cottrell",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expert-fraser.png",
  },
  {
    title: "Tom Brown",
    url: "/feeds/collections/experts/Tom%20Brown",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expert-tom.png",
  },
  {
    title: "Rahul Issar",
    url: "/feeds/collections/experts/Rahul%20Issar",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expert-rahul.png",
  },
  {
    title: "Zach Duncan",
    url: "/feeds/collections/experts/Zach%20Duncan",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expert-zach.png",
  },
  {
    title: "Austin Rabin",
    url: "/feeds/collections/experts/Austin%20Rabin",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expoert-austin.png",
  },
  {
    title: "Rabah Rahil",
    url: "/feeds/collections/experts/Rabah%20Rahil",
    description:
      "Spent millions of dollars at an agency, ran his own agency and most recently became CMO at Triple Whale.",
    ctaButton: "Get Rabah's Creatives",
    priceID: "prc_rabah-s-pack-mr2r0nth",
    planID: [
      "pln_rabah-s-pack-cp2q0nb6",
      "pln_rabah-s-creative-pack-obh70opq",
      "pln_rabah-legacy-pack-15fb0o8e",
    ],
    imageLink: "/expert-rabah-r.png",
  },
  {
    title: "Nick Shackelford",
    url: "/feeds/collections/experts/Nick%20Shackelford",
    description:
      "A pioneer in Facebook Ads for DTC brands. Co-founder of: Structured, Konstant Kreative & Brez.",
    ctaButton: "Get Nick's Creatives",
    priceID: "prc_nick-s-pack-s31n0nzl",
    planID: [
      "pln_nick-s-pack-lu1m0npx",
      "pln_nick-s-creative-pack-s6vn0n68",
      "pln_nick-s-pack-legacy-8ztv0nhz",
    ],
    imageLink: "/expert-nick-s.png",
  },
  {
    title: "Ash Melwani",
    url: "/feeds/collections/experts/Ash%20Melwani",
    description:
      "Co-Founder & CMO of Obvi, one of the world’s fastest growing health & nutrition brands.",
    ctaButton: "Get Ash's Creatives",
    priceID: "prc_ash-s-pack-sk2f0nrf",
    planID: [
      "pln_ash-s-pack-i41d01o8",
      "pln_ash-legacy-pack-gef30o1t",
      "pln_ash-s-creative-pack-gsh50oot",
      "",
    ],
    imageLink: "/expert-ash-m.png",
  },
  {
    title: "Barry Hott",
    url: "/feeds/collections/experts/Barry%20Hott",
    description:
      "Barry is a Growth Marketing Consultant & Advisor know for his expertise in growth and creative strategies.",
    ctaButton: "Get Barry's Creatives",
    priceID: "prc_barry-s-creative-pack-lkx40153",
    planID: [
      "pln_barry-s-pack-rd2w0ni3",
      "pln_barry-hot-expert-pack-u26u0hn0",
      "pln_barry-legacy-pack-yyf20our",
    ],
    imageLink: "/expert-barry-h.png",
  },
  {
    title: "Sarah Levinger",
    url: "/feeds/collections/experts/Sarah%20Levinger",
    description:
      "Sarah is a Forbes-featured consumer behavior analyst, creative strategist, and performance creative consultant.",
    ctaButton: "Get Sarah's Creatives",
    priceID: "prc_sara-s-creative-pack-hyvl0ns4",
    planID: [
      "pln_sarah-s-creative-pack-puvk0nz1",
      "pln_sarah-legacy-pack-ntu60nuz",
      "pln_sarah-s-pack-mv1601hd",
    ],
    imageLink: "/expert-sarah-l.png",
  },
  {
    title: "Dara Denney",
    url: "/feeds/collections/experts/Dara%20Denney",
    description:
      "Dara is a performance creative consultant and creator. She’s led creative and growth teams for the last 5 years.",
    ctaButton: "Get Dara's Creatives",
    priceID: "prc_dara-s-pack-kp1l01dv",
    planID: [
      "pln_dara-s-pack-hd1k015b",
      "pln_dara-legacy-pack-8gf40oh3",
      "pln_dara-s-creative-pack-4wvp0ncz",
    ],
    imageLink: "/expert-dara-d.png",
  },
  {
    title: "Alexa Kilroy",
    url: "/feeds/collections/experts/Alexa%20Kilroy",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expert-alexa.jpg",
  },
  {
    title: "Feras Khouri",
    url: "/feeds/collections/experts/Feras%20Khouri",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expert-feras.jpg",
  },
  {
    title: "Jess Chan",
    url: "/feeds/collections/experts/Jess%20Chan",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expert-jess.jpg",
  },
  {
    title: "Eric Rausch",
    url: "/feeds/collections/experts/Eric%20Rausch",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expert-eric.jpg",
  },
];

// const ExpertPackPlanID = "pln_expert-pack-cvuf01gf";

export const Route = createFileRoute("/feeds/collections/experts/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const [createBoardDialogOpen, setCreateBoardDialogOpen] =
    useState<boolean>(false);

  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  useEffect(() => {
    if (!isLoadingPermission && !permissionData?.userCanAccessEverything) {
      setUpgradeDialogOpen(true);
    }
  }, [permissionData?.userCanAccessEverything, isLoadingPermission]);

  return (
    <>
      {createBoardDialogOpen && (
        <BoardDialog
          open={createBoardDialogOpen}
          onOpenChange={() => setCreateBoardDialogOpen(false)}
        />
      )}
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <Stack className="gap-3 lg:gap-6">
        <Stack className="gap-3 lg:gap-8">
          <FeatureTabs
            defaultOrderValue={"Random"}
            useFirst2PathnamesOnly
            tabItems={[
              {
                name: "Curated",
                link: !(permissionData?.userCanAccessEverything ?? false)
                  ? "/feeds/collections/creativeos"
                  : "/feeds/collections",
              },
              {
                name: "Live Trends",
                link: "/feeds/live-trends",
              },
            ]}
          />
          <Stack className="gap-3 lg:gap-6">
            <Stack className="gap-2">
              <div className="gap-2 flex items-center justify-between">
                <Text weight="semibold" size={"xxl"}>
                  Collections
                </Text>

                <Text
                  weight="normal"
                  size={"base"}
                  className="text-thememutedforeground md:hidden max-w-fit"
                >
                  {(ExpertProducts ?? []).length}
                  {(ExpertProducts ?? []).length > 1
                    ? " Collections"
                    : " Collection"}
                </Text>
              </div>
              <Text
                weight="normal"
                size={"lg"}
                className="text-thememutedforeground hidden lg:flex"
              >
                Carefully crafted collections by the Creative OS team and our
                network of industry leaders.
              </Text>
            </Stack>

            <div className={"flex justify-between gap-6 items-center"}>
              <div className="flex gap-2 items-center">
                {[
                  ...(permissionData?.userCanAccessEverything
                    ? [
                        {
                          name: "All",
                          link: "/feeds/collections",
                          selected: false,
                        },
                      ]
                    : []),
                  {
                    name: "by CreativeOS",
                    link: "/feeds/collections/creativeos",
                    icon: (
                      <img
                        alt={""}
                        src={"/bg_gradient.jpg"}
                        className={"rounded-full shrink-0 bg-[#A259FF] w-4 h-4"}
                      />
                    ),
                  },
                  {
                    name: "Experts",
                    link: "/feeds/collections/experts",
                    icon: <UserWaves className="w-4 h-4" />,
                    isPremium: true,
                    selected: true,
                  },
                ].map((item) => (
                  <Link
                    to={item.link}
                    search={(d) => ({
                      ...d,
                      cacheBuster: Math.random(),
                      orderFilter: "Random",
                      sideBarOpen: false,
                    })}
                    activeOptions={{
                      exact: true,
                      includeSearch: false,
                    }}
                    key={item.name}
                  >
                    <Badge
                      variant={item.selected ? "default" : "outline"}
                      className="gap-1 cursor-pointer items-center"
                    >
                      {item.icon}
                      {item.name}
                      {item.isPremium && (
                        <>
                          {!isLoadingPermission &&
                            !permissionData?.userCanAccessEverything && (
                              <div className="opacity-50">
                                <LockTagSmall />
                              </div>
                            )}
                        </>
                      )}
                    </Badge>
                  </Link>
                ))}
              </div>

              <div className="gap-2 items-center hidden md:flex">
                <Button
                  onClick={() => setCreateBoardDialogOpen(true)}
                  size={"sm"}
                  variant={"ghost"}
                >
                  Create Your Own <Plus className="w-5 h-5" />
                </Button>
                <Text
                  weight="normal"
                  size={"base"}
                  className="text-thememutedforeground"
                >
                  {(ExpertProducts ?? []).length}
                  {(ExpertProducts ?? []).length > 1
                    ? " Collections"
                    : " Collection"}
                </Text>
              </div>
            </div>
          </Stack>
        </Stack>
        {!isLoadingPermission &&
          permissionData?.userCanAccessEverything &&
          ExpertProducts && <ExpertsGridView collections={ExpertProducts} />}
      </Stack>
    </>
  );
}

const ExpertsGridView = (props: BoardCollectionDataProps) => {
  return (
    <div className={"grid lg:grid-cols-2 gap-5 mb-10"}>
      {props.collections && props.collections.length > 0
        ? props.collections.map((item) => (
            <ExpertCollectionItem key={item.title} data={item} />
          ))
        : props.collections.length === 0 && (
            <div
              className={
                "h-full w-full lg:col-span-2 flex justify-center items-center"
              }
            >
              <p>No collections created</p>
            </div>
          )}
    </div>
  );
};

export const ExpertCollectionItem = ({ data }: { data: ExpertProductType }) => {
  const {
    data: adTemplates,
    isLoading,
    error,
  } = trpc.posts.useQuery(
    {
      Expert: data.title as Expert,
      Tags: undefined,
      limit: 6,
      cursor: undefined,
      Ready: true,
      sortingOptions: undefined,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  if (error) {
    return <ErrorDisplay />;
  }

  if (isLoading) {
    return (
      <div className={"flex justify-center items-center w-full h-full m-auto"}>
        <Loader />
      </div>
    );
  }

  return (adTemplates?.TotalRecords ?? 0) < 1 ? (
    <></>
  ) : (
    <Link to={data.url}>
      <Stack className="gap-3">
        <CollectionCard
          videoUrls={[]}
          imageUrls={
            adTemplates?.ATRecords?.map(
              (record) =>
                record.imageUrl ||
                record["Example Image"][0].thumbnails.large.url
            ) ?? []
          }
        />
        <div className="flex justify-between">
          <Stack className="gap-1 w-full">
            <h4
              className={`truncate font-semibold text-sm leading-5 text-themeforeground`}
            >
              {data.title}
            </h4>

            <div className={`flex gap-1 items-center`}>
              <img
                src={data.imageLink}
                alt={"CreativeOs"}
                className={`bg-[#BCBCBC] rounded-full w-6 h-6 object-contain`}
              />

              <Text
                weight={"normal"}
                size={"xs"}
                className={`text-thememutedforeground w-fit`}
              >
                by {data.title}
              </Text>
              <Badge variant={"destructivePurple"}>Expert</Badge>
            </div>
          </Stack>
          <Text
            className="text-thememutedforeground w-fit text-nowrap"
            size={"xs"}
            weight={"normal"}
          >
            {adTemplates?.TotalRecords ?? 0}{" "}
            {(adTemplates?.TotalRecords ?? 0) > 1 ||
            (adTemplates?.TotalRecords ?? 0) == 0
              ? " Items"
              : " Item"}
          </Text>
        </div>
      </Stack>
    </Link>
  );
};

import { Text } from "@/components/custom-components";
import { Box, Stack } from "@/components/custom-components/Layout";
import { Link } from "@tanstack/react-router";
import { Intercom } from "@intercom/messenger-js-sdk";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/shadcn/dialog.tsx";
import { useEffect, useState } from "react";
import { trpc } from "@/utils/trpc.ts";
import { ManageAccountV2 } from "@/components/templates/AppHeader";
import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";
import { Button } from "@/components/shadcn/button";
import {
  PanelRightOpen,
  PanelLeftOpen,
  Layout,
  Store,
  GridOne,
  Lightning,
  Star,
  MynaIconsProps,
  QuestionCircle,
  // MessageReply,
  CogThree,
  Tree,
  Swatches,
} from "@mynaui/icons-react";
import { CustomTestTubeIcon } from "@/components/custom-components/TestTubeIcon";
import { CustomDiamondIcon } from "@/components/custom-components/DiamondIcon";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { featureFlagKeys } from "@/utils/data/featureFlags";
// import { useUserback } from "@userback/react";
import { BoardDialog } from "@/components/BoardDialog.tsx";
import { StyledNavButton } from "@/components/StyledNavButton.tsx";
import { CustomLightBulb } from "@/components/shadcn/customLightBulb";

type SidebarProps = {
  isPublicView: boolean;
  sidebarCollapsed: boolean | undefined;
};

export const Sidebar = (props: SidebarProps) => {
  const [publicDialogOpen, setPublicDialogOpen] = useState<boolean>(false);
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);
  const [createBoardDialogOpen, setCreateBoardDialogOpen] =
    useState<boolean>(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);

  const { data: permissions, isLoading } = trpc.permissions.useQuery(null, {});
  const { data: allBoardsData, isLoading: boardsIsLoading } =
    trpc.getBoards.useQuery(
      { postType: "record" },
      {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  const flagEnabled = useFeatureFlagEnabled(featureFlagKeys.intercom);
  const { data: authUser } = trpc.me.useQuery(null, {
    refetchOnWindowFocus: false,
  });

  // const { show: showUserback } = useUserback();

  useEffect(() => {
    if (authUser && flagEnabled) {
      Intercom({
        app_id: import.meta.env.VITE_INTERCOM_KEY,
        user_id: authUser.id,
        name: `${authUser.firstName} ${authUser.lastName}`,
        email: authUser.email,
        created_at: parseInt(authUser.createdAt ?? "0"),
        user_hash: authUser.userHash,
      });
    }
  }, [authUser, flagEnabled]);

  const { data: timeSavedCounter } = trpc.getTimeSavedCounter.useQuery(
    undefined,
    {
      refetchOnWindowFocus: false,
    }
  );

  const collapsedSideNavigationList: Array<{
    name: string;
    link: string;
    icon: (props: MynaIconsProps) => JSX.Element;
    exact?: boolean;
    showNewTag?: boolean;
    collapsibleSidebar?: boolean;
  }> = [
    {
      name: "Templates",
      link: "/feeds/templates",
      icon: Layout,
    },
    {
      name: "Inspiration",
      link: "/feeds/inspiration",
      icon: CustomLightBulb,
    },
    {
      name: "Brands",
      link: "/feeds/brands",
      icon: Store,
    },
    {
      name: "Creative Toolkit",
      link: "/feeds/creative-toolkit",
      icon: Swatches,
      
    },
    {
      name: "My Collections",
      icon: Star,
      link: "/feeds/boards",
      exact: true,
    },
    {
      name: "Live Collections",
      icon: Lightning,
      link: "/feeds/live-trends",
    },

    {
      name: "Expert Collections",
      link: !(permissions?.userCanAccessEverything ?? false)
        ? "/feeds/collections/creativeos"
        : "/feeds/collections",
      icon: GridOne,
    },
  ];
  const expandedSideNavigationList: Array<{
    name: string;
    link: string;
    showNewTag?: boolean;
    icon: (props: MynaIconsProps) => JSX.Element;
  }> = [
    {
      name: "Templates",
      link: "/feeds/templates",
      icon: Layout,
    },
    {
      name: "Inspiration",
      link: "/feeds/inspiration",
      icon: CustomLightBulb,
    },
    {
      name: "Brands",
      link: "/feeds/brands",
      icon: Store,
    },
    {
      name: "Creative Toolkit",
      link: "/feeds/creative-toolkit",
      icon: Swatches,
      showNewTag: true
    },
  ];

  const collectionsNavigationList: Array<{
    name: string;
    icon: (props: MynaIconsProps) => JSX.Element;
    link?: string;
    isProFeature?: boolean;
    exact?: boolean;
    showPingIcon?: boolean;
    isMyCollections?: boolean;
    collapsibleSidebar?: boolean;
    subLinks?: Array<{
      id?: string;
      name: string;
      link?: string;
      first2Links?: string;
      first3Links?: string;
      isAddCollection?: boolean;
      ignoreProStatus?: boolean;
      icon?: JSX.Element;
      openCreateNewBoard?: () => void;
    }>;
  }> = [
    {
      name: "My Collections",
      icon: Star,
      isMyCollections: true,
      link: "/feeds/boards",
      exact: true,
      collapsibleSidebar: true,
      subLinks: boardsIsLoading
        ? [{ name: "Loading boards..." }]
        : [
            {
              name: "All Saved",
              link: "/feeds/saved/ads",
              first2Links: "/feeds/saved",
            },
            ...(allBoardsData?.map((item: { id: string; name: string }) => ({
              id: item.id,
              name: item.name,
              link: `/feeds/boards/${item.id}/ads`,
              first3Links: `/feeds/boards/${item.id}`,
            })) || []),
            {
              name: "Requested Templates",
              link: "/feeds/requested",
            },
            {
              name: "New Collection",
              isAddCollection: true,
              openCreateNewBoard: () => setCreateBoardDialogOpen(true),
            },
          ],
    },

    {
      name: "Live Collections",
      icon: Lightning,
      isProFeature: true,
      showPingIcon: true,
      // link: "/feeds/live-trends",
      collapsibleSidebar: true,
      subLinks: [
        {
          name: "Evergreen Ads",
          link: "/feeds/evergreen",
          icon: <Tree className="w-5 h-5" />,
        },
        {
          name: "Testing Lab",
          link: "/feeds/testing-labs",
          icon: <CustomTestTubeIcon width="20" height="20" color="#fff" />,
        },
        {
          name: "Hidden Gems",
          link: "/feeds/hidden-gems",
          icon: <CustomDiamondIcon width="20" height="20" color="#fff" />,
        },
        {
          name: "View All",
          link: "/feeds/live-trends",
          ignoreProStatus: true,
        },
      ],
    },
    {
      name: "Expert Collections",
      link: !(permissions?.userCanAccessEverything ?? false)
        ? "/feeds/collections/creativeos"
        : "/feeds/collections",
      icon: GridOne,
    },
  ];

  return (
    <>
      {createBoardDialogOpen && (
        <BoardDialog
          open={createBoardDialogOpen}
          onOpenChange={() => setCreateBoardDialogOpen(false)}
        />
      )}
      <Dialog open={publicDialogOpen} onOpenChange={setPublicDialogOpen}>
        <DialogContent className="sm:max-w-[500px] py-6">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Want the copy-and-paste templates for high-performing ads?
            </DialogTitle>
            <DialogDescription className={"text-lg mt-4"}>
              Sign up for a free trial and get access
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
              <Button type="submit">Get Free Access</Button>
            </a>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {upgradeDialogOpen && permissions && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissions}
        />
      )}

      <Box
        className={`w-full ${props.sidebarCollapsed ? "lg:w-[3rem]" : "lg:w-56"} flex-1 h-[96svh] lg:h-screen flex flex-col bg-white mt-12 lg:mt-0`}
      >
        <div
          id={"new-sidebar"}
          className={
            "overflow-y-auto bg-themeprimaryforeground border-r border-t border-themeinput flex flex-col justify-between flex-1"
          }
        >
          {!props.sidebarCollapsed ? (
            <div
              className={`h-full flex flex-col justify-between gap-5 w-full bg-red`}
            >
              <Stack>
                <div className={"px-2 pt-4 pb-4 hidden lg:flex w-full"}>
                  <div className="flex justify-between items-center gap-1 px-1 w-full">
                    <ManageAccountV2
                      closeModal={(val: boolean) => setProfileModalOpen(val)}
                      showModal={profileModalOpen}
                    />
                    <Link
                      search={(old) => ({
                        ...old,
                        sideBarOpen: false,
                        sidebarCollapsed: true,
                      })}
                      replace={true}
                      params={(old) => ({
                        ...old,
                      })}
                    >
                      <PanelRightOpen className="text-thememutedforeground  w-4 h-4" />
                    </Link>
                  </div>
                </div>
                <div className={"flex flex-col p-2 gap-1"}>
                  {expandedSideNavigationList.slice(0, 4).map((item, index) =>
                    StyledNavButton(index, item, {
                      isPublicView: props.isPublicView,
                      setPublicDialogOpen,
                      setUpgradeDialogOpen,
                      isLoading,
                      userCanAccessEverything:
                        permissions?.userCanAccessEverything,
                      userPlans: permissions?.usersPlans ?? [],
                      showNewTag: item.showNewTag
                    })
                  )}
                </div>
                <hr className={"border-themeinput"} />
                <Stack className="p-2 gap-1">
                  <Text
                    weight="normal"
                    size="xs"
                    className="text-thememutedforeground px-3 py-1.5"
                  >
                    Collections
                  </Text>
                  {collectionsNavigationList.map((item, index) =>
                    StyledNavButton(index, item, {
                      isPublicView: props.isPublicView,
                      setPublicDialogOpen,
                      setUpgradeDialogOpen,
                      isLoading,
                      userCanAccessEverything:
                        permissions?.userCanAccessEverything,
                      isMyCollections: item.isMyCollections,
                      isSidebarExpanded: true,
                      smallerPadding: true,
                      isProFeature: item?.isProFeature,
                      userPlans: permissions?.usersPlans ?? [],
                    })
                  )}
                </Stack>
              </Stack>

              <Stack
                className={"px-2 pt-2 pb-8 gap-3 text-thememutedforeground"}
              >
                {timeSavedCounter &&
                  timeSavedCounter.monthlyTimeSaved !== 0 &&
                  timeSavedCounter.totalSavedTime !== 0 && (
                    <div className={"rounded px-2.5 py-1 bg-purple-100 w-full"}>
                      <Text className={"text-xs font-medium"}>
                          💪 {timeSavedCounter.monthlyTimeSaved} hours Saved
                          this month!
                      </Text>
                      <p
                        className={
                          "font-light text-xs text-thememutedforeground"
                        }
                      >
                        You’ve saved {timeSavedCounter.monthlyTimeSaved} hours
                        this month and {timeSavedCounter.totalSavedTime} hours
                        all time by using Creative OS templates
                      </p>
                    </div>
                  )}
                <div
                  className="flex gap-1.5 text-nowrap items-center cursor-pointer"
                  onClick={() => setProfileModalOpen(true)}
                >
                  <CogThree className="w-5 h-5 min-w-5" />

                  <Text weight={"medium"} size={"sm"}>
                    Settings
                  </Text>
                </div>
                <a
                  href="https://intercom.help/creativeos/en/collections/11153232-how-to-use-creative-os"
                  target={"_blank"}
                  className="flex gap-1.5 text-nowrap items-center"
                >
                  <QuestionCircle className="w-5 h-5 min-w-5" />

                  <Text weight={"medium"} size={"sm"}>
                    Help Center
                  </Text>
                </a>
                {/*<span*/}
                {/*  onClick={showUserback}*/}
                {/*  className="flex gap-1.5 text-nowrap items-center cursor-pointer"*/}
                {/*>*/}
                {/*  <MessageReply className="w-5 h-5 min-w-5" />*/}

                {/*  <Text weight={"medium"} size={"sm"}>*/}
                {/*    Feedback*/}
                {/*  </Text>*/}
                {/*</span>*/}
              </Stack>
            </div>
          ) : (
            <div className={"h-full flex flex-col justify-between gap-5"}>
              <Stack>
                <div className={"px-2 pt-4 pb-4"}>
                  <Link
                    search={(old) => ({
                      ...old,
                      sideBarOpen: false,
                      sidebarCollapsed: false,
                    })}
                    replace={true}
                    params={(old) => ({
                      ...old,
                    })}
                    className={"hidden lg:flex justify-center"}
                  >
                    <PanelLeftOpen className="text-thememutedforeground w-4 h-4" />
                  </Link>
                </div>
                <hr className={"border-themeinput"} />

                <div className={"flex flex-col p-2 px-0 gap-1"}>
                  {collapsedSideNavigationList.map((item, index) => (
                    <Stack key={item.name}>
                      {index == collapsedSideNavigationList.length - 3 && (
                        <hr className={"border-themeinput my-2"} />
                      )}

                      <div className="px-2">
                        {StyledNavButton(index, item, {
                          isPublicView: props.isPublicView,
                          setPublicDialogOpen,
                          setUpgradeDialogOpen,
                          isLoading,
                          userCanAccessEverything:
                            permissions?.userCanAccessEverything,
                          isSidebarExpanded: false,
                          userPlans: permissions?.usersPlans ?? [],
                        })}
                      </div>
                    </Stack>
                  ))}
                </div>
              </Stack>

              <Stack
                className={"px-2 pt-2 pb-8 gap-3 text-thememutedforeground"}
              >
                <div
                  className="flex justify-center gap-1.5 cursor-pointer"
                  onClick={() => setProfileModalOpen(true)}
                >
                  <CogThree className="w-5 h-5 min-w-5" />
                </div>
                <a
                  href="https://intercom.help/creativeos/en/collections/11153232-how-to-use-creative-os"
                  className="flex justify-center gap-1.5 cursor-pointer"
                >
                  <QuestionCircle className="w-5 h-5 min-w-5" />
                </a>
                {/*<span*/}
                {/*  onClick={showUserback}*/}
                {/*  className="flex justify-center gap-1.5 cursor-pointer"*/}
                {/*>*/}
                {/*  <MessageReply className="w-5 h-5 min-w-5" />*/}
                {/*</span>*/}
              </Stack>
            </div>
          )}
        </div>
      </Box>
    </>
  );
};

export type BoardProp = {
  description?: string | null;
  name: string;
  id: string;
  isInBoard?: boolean;
};
